import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Col, Form, Input, Select, Button } from "antd";
import CrudIntegration from "../../logic/crudIntegration";

function ListTransactions(props) {
  const [fetching, setFetching] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [showSearch] = useState(false);

  const getTransactions = async () => {
    const records = await new CrudIntegration().get(
      'api/v1/admin-portal/records/transactions/'
    );
    console.log(records)
    if (records !== 400){
      setTransactions(records.records);
    }    
    setFetching(false);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const statusColors = {
    success: 'green',
    failed: 'red',
    pending: 'blue',
    processing: 'orange',
  }

  const columns = [
    { title: "Phone", dataIndex: "msisdn", key: "msisdn" },
    { title: "Customer", dataIndex: "customer", key: "customer" },
    { title: "Pdt", dataIndex: "policy", key: "policy" },
    { title: "Transaction", dataIndex: "transaction_type", key: "type" },
    { 
      title: "Amount", 
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      render: text => <span>{Number(text).toLocaleString()}</span>
  },
    
    
    { title: "Status", dataIndex: "transaction_status", key: "status", render: text => <Tag color={statusColors[text]}>{text}</Tag> },
    { 
      title: "Time",
      dataIndex: "time_created",
      key: "time_created",
      render: text => <span>{new Date(text).toLocaleString()}</span>
    },
  ];

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Transactions
      </h3>

        {
          showSearch ?
        <Form layout='vertical'>
          <Row gutter={10}>
          
            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Phone Number"
                name="msisdn"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Date From"
                name="from_date"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" type="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Date To"
                name="to_date"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" type="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Type"
                name="type"
                style={{width: '100%'}}
              >
                <Select>
                  <Select.Option>Premium Payment</Select.Option>
                  <Select.Option>Claim Settlement</Select.Option>
                  <Select.Option>Commission Disbursement</Select.Option>
                </Select>
              </Form.Item>
            </Col>


            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Status"
                name="status"
                style={{width: '100%'}}
              >
                <Select>
                  <Select.Option>Success</Select.Option>
                  <Select.Option>Failed</Select.Option>
                  <Select.Option>Processing</Select.Option>
                  <Select.Option>Pending</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Action"
                style={{width: '100%'}}
              >
                <Button style={{borderColor: 'blue'}}>Filter</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        :
        null}
        


      {fetching ? (
        <Skeleton active />
      ) : (
        <Table
          style={{ marginTop: "20px" }}
          dataSource={transactions}
          columns={columns}
          bordered
          size="small"
          pagination={{
            pageSize: 50
          }}
        />
      )}
    </React.Fragment>
  );
}

export default ListTransactions;
