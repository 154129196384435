import { callApi } from "../apiIntegration";
import { notification } from "antd";

class Payments {
  constructor(props, data) {
    this.props = props;
    this.data = data;
    this.notificationStatus = {
      200: "success",
      400: "error",
      401: "error",
    };
  }

  /**
   * Get the payments
   */
  async getPayments() {
    const apiResponse = await callApi({
      requireAuth: true,
      method: "GET",
      // body: {},
      endpoint: `/api/v1/finance/payments/?status=Success&msisdn=2567777&from=YYYY-MM-DD&to=YYYY-MM-DD&product=productid`,
      contentType: "application/json",
    });
    if (apiResponse.status === 0) {
      //store.dispatch(setServiceTickets(apiResponse.records));
      console.log(apiResponse.records)
      return apiResponse
    } else {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
    }
  }
}

export default Payments;
