import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Tabs, Descriptions } from "antd";
import AddClaimModal from "./components/AddClaimModal";
import { UserOutlined, SnippetsOutlined, SyncOutlined, FileSyncOutlined, GiftOutlined, SoundOutlined } from "@ant-design/icons";
import CrudIntegration from "../../logic/crudIntegration";
import { Link } from 'react-router-dom';
import PersonalInfo from "../GeneralComponents/PersonalInfo";
import PolicyDetails from "../GeneralComponents/PolicyDetails";
import RecordIndividualPaymentModal from "./modals/RecordInvidualPayment";


// const products = useSelector((state) => state.products.list);
const {TabPane} = Tabs;

function PolicyDetail(props) {
  const [fetching, setFetching] = useState(true);
  const [policyProfile, setPolicyProfile] = useState({
    customer: {},
    claims: []
  });


  const PolicyStatusColors = {
    pending: 'blue',
    waiting: 'cyan',
    active: 'green',
    suspended: 'orange',
    cancelled: 'red',
    expired: 'red'
  }

  

  const getPolicyDetails = async () => {
    const detail = await new CrudIntegration().get(
        `api/v1/admin-portal/records/details/?record=policy-profiles&id=${props.match.params.id}`
    );
    setPolicyProfile(detail);
    setFetching(false);
  };

  useEffect(() => {
    getPolicyDetails()
  }, []);

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Policy Details
      </h3>

      {
      fetching ? <Skeleton active /> : 
        <React.Fragment>

            <Tabs
                defaultActiveKey="personalInfo"
                tabPosition='top'
                type='card'
                tabBarGutter={10}
            >
                <TabPane
                    tab={<span><UserOutlined />Personal Info</span>}
                    key="personalInfo">
                    {/* <UpdatePersonalInfoModal /> */}
                    <PersonalInfo record={policyProfile.customer} />
                </TabPane>

                <TabPane
                    tab={<span><SnippetsOutlined />Policy Details</span>}
                    key="policyDetails">
                    <PolicyDetails policy={policyProfile} />
                </TabPane>

                <TabPane
                    tab={<span><GiftOutlined />Benefits</span>}
                    key="benefits">
                    <Benefits benefits={policyProfile.benefits} />
                </TabPane>

                <TabPane
                    tab={<span><FileSyncOutlined />Claims</span>}
                    key="claims">
                        <Claims
                            {...props} 
                            claims={policyProfile.claims}
                        />
                </TabPane>

                <TabPane
                    tab={<span><SyncOutlined />Transactions</span>}
                    key="transactions">
                    <Transactions
                      customer_policy_id={policyProfile.id}
                      customer_name={`${policyProfile.customer.first_name} ${policyProfile.customer.last_name}`}
                      transactions={policyProfile.transactions} />
                </TabPane>

                <TabPane
                    tab={<span><SoundOutlined/>Support tickets</span>}
                    key="tickets">
                    <Row style={{padding: '5px'}}>
                        the support tickets that the customer has raised regarding the policy
                    </Row>
                </TabPane>               

            </Tabs>

        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default PolicyDetail;


function Claims(props){

    const ClaimStatusColors = {
        initiated: 'blue',
        submitted: 'cyan',
        processing: 'orange',
        settled: 'green',
        pending_settlement_approval: 'orange',
        settlement_approved: 'orange',
        rejected: 'red'
      }

    const columns = [
        { 
            title: "Amount Claimed",
            dataIndex: "claimed_amount",
            key: "claimed_amount",
            render: (text, record) => <Link to={`/claim-detail/${record.id}`}>{Number(parseFloat(text)).toLocaleString()}</Link>,
        },
        { title: "Status", dataIndex: "claim_status", key: "status", render: text => <Tag color={ClaimStatusColors[text]}>{text}</Tag> },
        { title: "Amount Settled", dataIndex: "settled_amount", key: "settled_amount", render: text => <span>{Number(parseFloat(text)).toLocaleString()}</span> },
        { title: "Int. Date", dataIndex: "time_created", key: "time_created", render: text => <span>{new Date(text).toLocaleString()}</span> },
      ];


    return <React.Fragment>
        <AddClaimModal
            policyId={props.match.params.id}
            policyName='Muno Mukabi'
        />

        <Table
          style={{ marginTop: "20px" }}
          dataSource={props.claims}
          columns={columns}
          bordered
          size="small"
        />

    </React.Fragment>
}

function Transactions(props){
  const statusColors = {
    success: 'green',
    failed: 'red',
    pending: 'blue',
    processing: 'orange',
  }

  const columns = [
    { title: "Phone", dataIndex: "msisdn", key: "msisdn" },
    { title: "Customer", dataIndex: "customer", key: "customer" },
    { title: "Pdt", dataIndex: "policy", key: "policy" },
    { title: "Transaction", dataIndex: "transaction_type", key: "type" },
    { 
      title: "Amount", 
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      render: text => <span>{Number(text).toLocaleString()}</span>
  },
    
    
    { title: "Status", dataIndex: "transaction_status", key: "status", render: text => <Tag color={statusColors[text]}>{text}</Tag> },
    { 
      title: "Time",
      dataIndex: "time_created",
      key: "time_created",
      render: text => <span>{new Date(text).toLocaleString()}</span>
    },
  ];

  return <React.Fragment>
    <RecordIndividualPaymentModal 
      customer_policy_id={props.customer_policy_id}
      customer_name={props.customer_name}
    />
    <Table
      style={{ marginTop: "20px" }}
      dataSource={props.transactions}
      columns={columns}
      bordered
      size="small"
    />
    </React.Fragment>
}


function Benefits(props){
  const columns = [
    { title: "Benefit", dataIndex: "benefit", key: "benefit_name", render: text => <span>{text.name}</span> },
    { title: "Enabled", dataIndex: "enabled", key: "enabled", render: text => <span>{text ? 'Yes' : 'No'}</span> },    
    { title: "Max Benefit Value", dataIndex: "max_benefit_value", key: "max_benefit_value", render: text => <span>{Number(parseFloat(text)).toLocaleString()}</span> },
    { title: "Claims Used", dataIndex: "claims_consumed", key: "claims_consumed", render: text => <span>{Number(parseFloat(text)).toLocaleString()}</span> },
    { title: "Cum. Claims", dataIndex: "cumulative_claims_consumed", key: "cumulative_claims_consumed", render: text => <span>{Number(parseFloat(text)).toLocaleString()}</span> },
  ];

  return <Table
    style={{ marginTop: "20px" }}
    dataSource={props.benefits}
    columns={columns}
    bordered
    size="small"
    pagination={{
      pageSize:50
    }}
  />
}