import React from "react";
import { coloredClassName, okButton } from "../../styleClassNames";
import { Button, Table, Tag, Space} from "antd";
import { useSelector } from "react-redux";
import DisableModal from "./DisableModal";


const ListProductOwners = (props) => {
  const productOwners = useSelector((state) => {
    return state.productOwners.list;
  });

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Address", dataIndex: "address", key: "address" },
    // {title: 'Email', dataIndex: 'email', key: 'email'},
    // {title: 'Period', dataIndex: 'policyPeriod', key: 'policyPeriod'},
    // {title: 'Cost', dataIndex: 'cost', key: 'cost', render: text =><span>{Number(text).toLocaleString()}</span>},
    // {title: 'Owner', dataIndex: 'ownerDetails', key: 'ownerDetails', render: (text, record) => (<span>{record.ownerDetails.name}</span>)},
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Tag
            color="blue"
            onClick={() => {
              props.history.push("/add-product-owner/", {
                itemToEdit: record,
                isEditing: true,
              });
            }}
          >
            Edit
          </Tag>
          <DisableModal productOwner={record} />
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Product Owners
      </h3>
      <Button
        className={okButton}
        onClick={() => {
          props.history.push("/add-product-owner/");
        }}
      >
        Add
      </Button>
      <Table
        style={{ marginTop: "20px" }}
        dataSource={productOwners}
        columns={columns}
        bordered
        size="small"
        expandable
        pagination
      />
    </React.Fragment>
  );
};

export default ListProductOwners;
