import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Col, Form, Input, Select, Button, notification } from "antd";
import CrudIntegration from "../../logic/crudIntegration";
import AddSmeModal from "./Modals/AddSme";
import UploadBeneficiaryListModal from "./Modals/UploadBeneficiaryList";
import RecordSmePaymentModal from "./Modals/RecordPayment";

function ListSmes(props) {
  const [fetching, setFetching] = useState(true);
  const [smes, setSmes] = useState([]);
  const [products, setProducts] = useState([]);

  const getSmes = async () => {
    const records = await new CrudIntegration().get(
      'api/v1/admin-portal/records/smes/'
    );
    try{
        if (records !== 400){
        setSmes(records.records);
        }  
    } catch(err){
        notification.error({
            description: 'Sorry, an error occurred while retrieving the list of SMEs'
        })
    }
      
    setFetching(false);
  };

  const getProducts = async () => {
    const records = await new CrudIntegration().get(
      'api/v1/admin-portal/records/products/'
    );
    try{
        if (records !== 400){
        setProducts(records.records);
        }  
    } catch(err){
        notification.error({
            description: 'Sorry, an error occurred while retrieving the list of products'
        })
    }
    setFetching(false);
  };

  const addSme = async data => {
    const records = await new CrudIntegration().create(
        'api/v1/admin-portal/records/smes/',
        data,
        'application/json'
    );
    try{
        if (records !== 400){ getSmes()}  
    } catch(err){
        notification.error({
            description: 'Sorry, an error occurred while creating the SME'
        })
    }
      
    setFetching(false);
  };

  useEffect(() => {
    getSmes();
    getProducts();
  }, []);

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Contact Person", dataIndex: "contact_person", key: "contact_person" },
    { title: "Product", dataIndex: "policy", key: "policy" },
    { title: "No. Members", dataIndex: "no_members", key: "no_members" },
    {
      title: "Cum. Payments", 
      dataIndex: "cum_payments",
      key: "cum_payments",
      render: text => <span>{Number(text).toLocaleString()}</span>
    },
    {
      title: "Time",
      dataIndex: "time_created",
      key: "time_created",
      render: text => <span>{new Date(text).toLocaleString()}</span>
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => <span>
        <UploadBeneficiaryListModal sme={record} getSmes={getSmes} />
        <RecordSmePaymentModal sme={record}/>
        </span>
    },
  ];

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        SMEs
      </h3>

      <Row>
        <AddSmeModal addSme={addSme} products={products}/>
      </Row>

      {fetching ? (
        <Skeleton active />
      ) : (
        <Table
          style={{ marginTop: "20px" }}
          dataSource={smes}
          columns={columns}
          bordered
          size="small"
        />
      )}
    </React.Fragment>
  );
}

export default ListSmes;
