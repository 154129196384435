import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input, notification
} from "antd";
 //import signinbg from "../assets/images/img-signin.jpg";
 import signinbg from "../assets/images/curved_login.jpg";

import Auth from "../logic/auth";


const { Title } = Typography;
const { Footer, Content } = Layout;

export default function Login(props){
  const [fetching, setFetching] = useState(false);
  
  const SubmitLogin = async data => {
    try{
      setFetching(true);
      let loginData = data;
      loginData.userType = 'admin';
      await new Auth(props, loginData).login();
      setFetching(false);
    } catch(error){
      console.log(error);
      setFetching(false);
      notification.error({
        description: "An error occurred while attempting to login",
      });
    }
  }

  useEffect(()=>{
    document.title="Weerinde";
    sessionStorage.clear();
  })

  return (
    <>
      <Layout className="layout-default layout-signin" style={{minHeight: '100vh'}}>
        <Content className="signin overflow-hidden bg-center bg-cover min-h-75-screen">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <div className="pt-6 pb-0 mb-8 px-0 bg-transparent border-b-0 rounded-t-2xl">
               <h3 style={{fontSize:'1.875rem'}} className="text-2xl relative z-10 font-bold text-transparent bg-gradient-to-tl from-[#413E79] to-[#F8B34E] bg-clip-text">Weerinde</h3>
               <p>Enter your email and password to sign in</p>
                  </div>
              <Form
                onFinish={SubmitLogin}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your authorised email",
                    },
                  ]}
                >
                  <Input placeholder="e.g. you@weerindeinsurance.co.ug" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your login password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                  className="bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]"
                    type="primary"
                    htmlType="submit"     
                    style={{ width: "100%",marginTop:'12px' }}
                    disable={fetching}
                    loading={fetching}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
               
            </Col>
            <Col 
                          className="sign-img"
                          style={{ padding: 12,overflow:'hidden' }}
                          xs={{ span: 24 }}
                          lg={{ span: 12 }}
                          md={{ span: 12 }}>
            <div className="px-3 lg:flex-0 shrink-0 md:w-6/12">
                <div className="absolute top-0 hidden w-3/5 h-full overflow-hidden -skew-x-10 -right-20 rounded-bl-xl md:block">
                  <div className="absolute inset-x-0 top-0 z-0 h-full -ml-16 bg-cover skew-x-10" style={{backgroundImage: "url("+signinbg+")"}}></div>
                </div>
              </div>
              </Col>
{/*             <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
          {       }
            {   <img src={signinbg} alt="" /> }
            </Col> */}
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2023 - {new Date().getFullYear()} Weerinde by KSG I.T (U) Ltd
          </p>
        </Footer>
      </Layout>
    </>
  );
}


