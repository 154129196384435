import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag } from "antd";
import { useSelector } from "react-redux";
import TicketModal from "./TicketModal";
import ServiceTickets from "../../logic/serviceTickets";

const columns = [
  { title: "Contact", dataIndex: "msisdn", key: "msisdn" },

  { title: "Policy", dataIndex: "policy", key: "policy" },

  { title: "Status", dataIndex: "status", key: "status" },
  {
    title: "Date Initiated",
    dataIndex: "timeInitiated",
    key: "timeInitiated",
    render: (text) => {
      const date = new Date(text.timeSeconds);
      console.log(date);
      return <span>{`${date}`}</span>;
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (index, record) => (
      <span>
        <TicketModal ticket={record} status={record.status} />
        <TicketModal ticket={record} status="closed" />
        {/* <Tag color="red">Close</Tag> */}
      </span>
    ),
  },
];

const ListServiceTickets = (props)=> {
  const [tickets, setTickets] = useState(null);
  const getTickets = async () => {
    await new ServiceTickets(props)
      .getServiceTickets()
      .then((result) => setTickets(result.records));
  };
  
  useEffect(()=> {
    getTickets();
  }, [props])

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Service Tickets
      </h3>
      <Table
        style={{ marginTop: "20px" }}
        dataSource={tickets}
        columns={columns}
        bordered
        size="small"
      />
    </React.Fragment>
  );
}

export default ListServiceTickets;
