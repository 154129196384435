import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Modal, Form, InputNumber, Input, notification, Checkbox, Tag,
    Card, Radio, Row, Col, Upload
  } from "antd";
import CrudIntegration from '../../../logic/crudIntegration';

const UpdateClaim = (props) => {
    const { Title } = Typography;
    const [fetching, setFetching] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ confirmation, setConfirmation] = useState(false);

    const [benefits, setBenefits] = useState([
        {title: 'OPD', maxBenefits: 300000, sysMaxAdvisedBenefits: 20000, adjustedAmount: 0},
        {title: 'InPatient', maxBenefits: 1500000, sysMaxAdvisedBenefits: 50000, adjustedAmount: 0}
    ]);
    

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm()

    useEffect(()=>{
      form.setFieldsValue(props.profile);
    })

    const uploadDocument = async data => {
        console.log(data)
    }

    return (
      <React.Fragment>
        <Button onClick={showModal} type="button">
        Update Claim
      </Button>

      <Modal
        title={<center><Title level={5}>Update claim</Title></center>}
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1000}
        >

        <Form
            form={form}
            encType="multipart/form-data"
            name="upload_supporting_document"
            layout = 'vertical'
            onFinish={uploadDocument}
            requiredMark
            clear
            >
                <Form.Item
                  name="notes"
                  label="Notes"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please provide notes",
                    },
                  ]}
                >
                  <Input.TextArea rows={3}/>
                </Form.Item>

                <hr/>
                <h4>Settlement Details</h4>

                {
                    benefits.map((benefit, index) => {
                        return <Row>
                            <Col xs={8} md={8} lg={8}>{benefit.title} (up to{Number(benefit.maxBenefits).toLocaleString()})</Col>
                            <Col xs={8} md={8} lg={8}>Max. advised amt. {Number(benefit.sysMaxAdvisedBenefits).toLocaleString()}</Col>
                            <Col xs={8} md={8} lg={8}>
                                <Form.Item>
                                <InputNumber 
                                    placeholder="e.g. 1,000,000" 
                                    style={{width: "100%"}}
                                    min='5000'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                            <hr/>
                        </Row>
                    })
                }

                <Checkbox 
                    style={{
                        marginTop: "15px", 
                        marginBottom: "15px"
                    }}
                    onChange={()=>{setConfirmation(!confirmation);}}
                    value={confirmation}
                >I confirm that the above details are valid</Checkbox>

                { confirmation ?
                    <Form.Item>
                        <Button 
                          htmlType="html"
                          disable={fetching}
                          loading={fetching}
                          style={{ width: "100%" }}
                          >Submit settlement details</Button>
                    </Form.Item>
                    : null
                }

            </Form>
      </Modal>
      </React.Fragment>
    );
  }
  
  export default UpdateClaim;
  