import { createSlice } from '@reduxjs/toolkit';

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    fetching: true,
    list: []
  },
  reducers: {
    setProducts: (state, action) => {
        state.fetching = false;
        state.list = action.payload;
    },
    }
});

// this is for dispatch
export const { setProducts } = productsSlice.actions;

// this is for configureStore
export default productsSlice.reducer;