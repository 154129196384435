import React from "react";
import { coloredClassName, okButton } from "../../styleClassNames";
import { Button, Space, Table, Tag } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DisableModal from "./DisableModal";

const columns = [
  { title: "Product Name", dataIndex: "productName", key: "productName" },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text) => (
      <span>
        <Tag>{text}</Tag>
      </span>
    ),
  },
  { title: "Period", dataIndex: "policyPeriod", key: "policyPeriod" },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
    render: (text) => <span>{Number(text).toLocaleString()}</span>,
  },
  {
    title: "Owner",
    dataIndex: "ownerDetails",
    key: "ownerDetails",
    render: (text, record) => <span>{record.ownerDetails.name}</span>,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => (
      <Space size="small">
        <Link
          to={{
            pathname: "/add-product/",
            state: { itemToEdit: record, isEditing: true },
          }}
        >
          <Tag
            color="green"
          >
            Edit
          </Tag>
        </Link>
        {/* <Tag color="blue">Configure Benefits</Tag> */}
        {/* <Tag color="red">Disable</Tag> */}
        <DisableModal product={record} />
      </Space>
    ),
  },
];

// const products = useSelector((state) => state.products.list);

function ListProducts(props) {
  // useEffect(()=>{
  //     new Products(props).get();
  // })
  const products = useSelector((state) => state.products.list);

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Products
      </h3>
      <Button
        className={okButton}
        onClick={() => {
          props.history.push("/add-product/");
        }}
      >
        Add Product
      </Button>

      <Table
        style={{ marginTop: "20px" }}
        dataSource={products}
        columns={columns}
        bordered
        size="small"
      />
    </React.Fragment>
  );
}

export default ListProducts;
