import React, { useState } from "react";
import { Typography, Button, Modal, Form, Input, Tag } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const okButton =
  "bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]";

const TicketModal = (props) => {
  const { Title } = Typography;
  const [fetching, setFetching] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    const finalValues = {
      _id: props.ticket._id,
      status: props.status,
      comment: values.comment,
    };
    // setFetching(true);
    // await new Products(props, finalValues).delete().then(() => handleCancel());
    // setFetching(false);
  };

  return (
    <React.Fragment>
      <Link>
        <Tag color={props.status === 'open' ? "green" : "red"} onClick={showModal}>
          {props.status === 'open' ? 'Update' : 'Close'}
        </Tag>
      </Link>
      <Modal
        title={
          <center>
            <Title level={5}>{props.status === 'closed' ? `Close` : `Update`} Ticket</Title>
          </center>
        }
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Form
          form={form}
          name="remit_revenue"
          layout="vertical"
          onFinish={handleFinish}
          requiredMark
          clear
        >
          <Form.Item
            name="comment"
            label="Comments"
            required
            rules={[
              {
                required: true,
                message: "Please enter the comments for this action",
              },
            ]}
          >
            <Input.TextArea rows={3} placeholder="Provide comments for the action being taken about the ticket" />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className={okButton}
              style={{ width: "100%" }}
              disable={fetching}
              loading={fetching}
            >
              <span style={{color: 'white'}}>Submit</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default TicketModal;
