import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Col, Form, Input, Select, Button } from "antd";
import PolicyProfiles from "../../logic/policyProfiles";
import { Link } from 'react-router-dom';


function ListPolicyProfiles(props) {
  const [fetching, setFetching] = useState(true);
  const [policyProfiles, setPolicyProfiles] = useState([]);
  const [showSearch] = useState(false);
  
  const getPolicies = async () => {
    const policyProfiles = await new PolicyProfiles().get();
    setPolicyProfiles(policyProfiles.records);
    setFetching(false);
  };

  const PolicyStatusColors = {
    pending: 'blue',
    waiting: 'cyan',
    active: 'green',
    suspended: 'orange',
    cancelled: 'red',
    expired: 'red'
  }

  useEffect(() => {
    getPolicies();
  }, []);

  

  const columns = [
    {
      title: "Customer Phone",
      dataIndex: "customer",
      key: "customerContact",
      render: (text, record) => <Link to={`/policy-detail/${record.id}`}>{text.phone_number}</Link>,
      fixed: 'left'
    },
    {
      title: "SME",
      dataIndex: "sme",
      key: "sme",
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customer",
      render: (text) => (
        <span>{text.name}</span>
      ),
    },    
    {
      title: "Pro. Com(%)",
      dataIndex: "customer",
      key: "customerContact",
      render: (text) => <span>{text.profile_completion}</span>,
    },
    {
      title: "Channel",
      dataIndex: "customer",
      key: "customerContact",
      render: (text) => <span>{text.channel}</span>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text) => <span>{text.name}</span>,
    },
    { title: "Pol. No", dataIndex: "policy_number", key: "policy_number" },
    { title: "Status", dataIndex: "policy_status", key: "status", render: (text) => <Tag color={PolicyStatusColors[text]}>{text}</Tag>},
    { title: "No. Lives", dataIndex: "no_lives", key: "no_lives"},
    { title: "Pol. Start", dataIndex: "calculated_start_date", key: "start_date" },
    { title: "Pol. End", dataIndex: "calculated_end_date", key: "end_date" },
    { title: "Last Payment", dataIndex: "last_payment_date", key: "last_payment_date" },
    { title: "Ann. Cum. Pay", dataIndex: "annual_cumulative_payments", key: "annual_cumulative_payments", render: text => <span>{Number(text).toLocaleString()}</span> },
    { title: "Cum. Claims", dataIndex: "annual_cumulative_claims", key: "annual_cumulative_claims", render: text => <span>{Number(parseInt(text).toLocaleString())}</span> },
    { title: "Time Created", dataIndex: "time_created", key: "time_created", render: text => <span>{new Date(text).toLocaleString()}</span> },
  ];

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Insurance Policies
      </h3>

        {
          showSearch ? 
        <Form layout='vertical'>
          <Row gutter={10}>
          
            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Policy Number"
                name="policy_number"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Phone Number"
                name="msisdn"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Policy Start"
                name="policy_start"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" type="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Policy End"
                name="policy_end"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" type="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Status"
                name="status"
                style={{width: '100%', height: '20px'}}
              >
                <Select>
                  <Select.Option>Pending Payment</Select.Option>
                  <Select.Option>Waiting</Select.Option>
                  <Select.Option>Active</Select.Option>
                  <Select.Option>Expired</Select.Option>
                  <Select.Option>Suspended</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Action"
                name="status"
                style={{width: '100%'}}
              >
                <Button style={{borderColor: 'blue'}}>Filter</Button>
              </Form.Item>
            </Col>
          
          </Row>
        </Form>
        :
        null }
        


      {fetching ? (
        <Skeleton active />
      ) : (
        <Table
          responsive
          style={{ marginTop: "5px" }}
          dataSource={policyProfiles}
          columns={columns}
          bordered
          size="small"
          pagination={{
            pageSize: 50,
            // showSizeChanger: true,
            // pageSizeOptions: ["25", "50", "100"],
          }}
          // onRow={(record, index) => {
          //   onClick: event => {}
          // }}
        />
      )}
    </React.Fragment>
  );
}

export default ListPolicyProfiles;
