
import { callApi } from "../apiIntegration";
import { notification } from 'antd';


class CrudIntegration {
    /**
     * create a claim
     */
    async create(
        endpoint,
        data,
        contentType
    ){
        let _contentType = contentType
        let apiResponse = null;
        if (_contentType === 'application/json'){
            apiResponse = await callApi({
                requireAuth: true,
                method: "POST",
                body: JSON.stringify(data),
                endpoint: endpoint,
                contentType: contentType,
            })
        } else {
            apiResponse = await callApi({
                requireAuth: true,
                method: "POST",
                body: data,
                endpoint: endpoint,
            })
        }
        try{
            // const 
            if (apiResponse.status === 200){
                notification.success({description: apiResponse.message})
            } else {
                notification.error({description: apiResponse.message})
            }
            return apiResponse.status
        } catch(err){
            notification.error({description: 'Sorry, an error occurred. Please try again'})
            return 400
        }       
    }

    async get(endpoint){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: endpoint,
            contentType: "application/json",
          })
        try{
            if (apiResponse.status === 200){
                return apiResponse.data
            } else {
                notification.error({
                    description: apiResponse.message
                })
                return 400
            }
        } catch(err){
            notification.error({description: 'Sorry, an error occurred. Please try again'})
            return 400
        }
        
    }

    async update(endpoint, data, contentType) {
        let _contentType = contentType
        let apiResponse = null;
        if (_contentType === 'application/json'){
            apiResponse = await callApi({
                requireAuth: true,
                method: "PUT",
                body: JSON.stringify(data),
                endpoint: endpoint,
                contentType: contentType,
            })
        }
        if (apiResponse.status === 200){
            notification.success({description: apiResponse.message})
        } else {
            notification.error({description: apiResponse.message})
        }
        return apiResponse.status
    }
}

export default CrudIntegration;