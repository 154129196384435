import React, { useState } from "react";
import { Typography, Button, Modal, Form, Input, Tag } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Products from "../../logic/products";

const okButton =
  "bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]";

const DisableModal = (props) => {
  const { Title } = Typography;
  const [fetching, setFetching] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    const finalValues = {
      _id: props.product._id,
      reason: values.reason,
    };
    setFetching(true);
    await new Products(props, finalValues).delete().then(() => handleCancel());
    setFetching(false);
  };

  return (
    <React.Fragment>
      <Link>
        <Tag color="red" onClick={showModal}>
          Disable
        </Tag>
      </Link>
      <Modal
        title={
          <center>
            <Title level={5}>Remove / Disable Product</Title>
          </center>
        }
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Form
          form={form}
          name="remit_revenue"
          layout="vertical"
          onFinish={handleFinish}
          requiredMark
          clear
        >
          <Form.Item
            name="reason"
            label="Reason for the disable"
            required
            rules={[
              {
                required: true,
                message: "Please enter the reason for this action.",
              },
            ]}
          >
            <Input placeholder="e.g. product no longer valid" />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className={okButton}
              style={{ width: "100%" }}
              disable={fetching}
              loading={fetching}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default DisableModal;
