import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Descriptions, Tabs } from "antd";
import { FileSyncOutlined, InfoCircleOutlined, UserOutlined, SnippetsOutlined } from "@ant-design/icons";
import AddSupportingDocument from "./components/AddDocument";
import UpdateClaim from "./components/UpdateClaim";
import CrudIntegration from "../../logic/crudIntegration";
import PersonalInfo from "../GeneralComponents/PersonalInfo";
import PolicyDetails from "../GeneralComponents/PolicyDetails";
import {Link} from 'react-router-dom';


// const products = useSelector((state) => state.products.list);
const {TabPane} = Tabs;

function ClaimDetail(props) {
  const [fetching, setFetching] = useState(true);
  const [claim, setClaim] = useState({
    customer: {},
    policy: {},
    supporting_documents: []
  });


  const PolicyStatusColors = {
    pending: 'blue',
    waiting: 'cyan',
    active: 'green',
    suspended: 'orange',
    cancelled: 'red',
    expired: 'red'
  }

  const getClaimDetails = async () => {
    const detail = await new CrudIntegration().get(
        `api/v1/admin-portal/records/details/?record=claims&id=${props.match.params.id}`
    );
    setClaim(detail);
    setFetching(false);
  };

  useEffect(() => {
    getClaimDetails()
  }, []);

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Claim Details
      </h3>

      {
      fetching ? <Skeleton active /> : 
        <React.Fragment>

            <Tabs
                defaultActiveKey="claimInfo"
                tabPosition='top'
                type='card'
                // centered
                tabBarGutter={10}
            >
                <TabPane
                    tab={<span><UserOutlined />Personal Info</span>}
                    key="personalInfo">
                    <PersonalInfo record={claim.customer} />
                </TabPane>

                <TabPane
                    tab={<span><SnippetsOutlined />Policy Info</span>}
                    key="policyInfo">
                    <PolicyDetails policy={claim.policy} />
                </TabPane>

                <TabPane
                    tab={<span><InfoCircleOutlined />Claim Info</span>}
                    key="claimInfo">
                    <ClaimInfo record={claim} />
                </TabPane>

                <TabPane
                    tab={<span><FileSyncOutlined />Supporting Documents</span>}
                    key="supportingDocs">
                <SupportingDocuments {...props} docs={claim.supporting_documents}/>
                </TabPane>
            </Tabs>

        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default ClaimDetail;


function SupportingDocuments(props){
    const DocReviewStatusColors = {
        pending: 'blue',
        accepted: 'green',
        rejected: 'red'
    }
    const columns = [
        { 
            title: "File",
            dataIndex: "file_name", 
            key: "file",
            render: (text, record) => <Link to={`${record.file}`}>{text}</Link>
        },
        { title: "Status", dataIndex: "file_review_status", key: "status", render: text => <Tag color={DocReviewStatusColors[text]}>{text}</Tag> },
        { title: "Status Reason", dataIndex: "file_review_status_reason", key: "file_review_status_reason"},
    ]
    return <React.Fragment>
        <AddSupportingDocument claim={props.match.params.id} />
        <Table
          style={{ marginTop: "20px" }}
          dataSource={props.docs}
          columns={columns}
          bordered
          size="small"
        />
    </React.Fragment>
}

function ClaimInfo(props){
    const info = props.record
    const ClaimStatusColors = {
        initiated: 'blue',
        submitted: 'cyan',
        processing: 'orange',
        settled: 'green',
        pending_settlement_approval: 'orange',
        settlement_approved: 'orange',
        rejected: 'red'
    }

    return (
        <React.Fragment>
            <Descriptions title="Claim Details" bordered style={{marginBottom: '10px'}}>
                <Descriptions.Item span={6} label="Time Intimated">{new Date(info.time_created).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item span={6} label="Claimed Amount">{Number(info.claimed_amount).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item span={6} label="Submission Notes">{info.claim_submission_notes}</Descriptions.Item>
                <Descriptions.Item span={6} label="Channel of claim">{info.claim_channel}</Descriptions.Item>
                <Descriptions.Item span={6} label="Service Provider">{info.sp_name}</Descriptions.Item>
                <Descriptions.Item span={6} label="Service Telephone">{info.sp_telephone}</Descriptions.Item>
                <Descriptions.Item span={6} label="Service Location">{info.sp_location}</Descriptions.Item>
                <Descriptions.Item span={6} label="Time Last Updated">{new Date(info.time_last_updated).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item span={6} label="Claim status"><Tag color={ClaimStatusColors[info.claim_status]}>{info.claim_status}</Tag></Descriptions.Item>
            </Descriptions>

            <Descriptions title="Adjustment and Settlement Details" bordered>
                <Descriptions.Item span={6} label="Adjustment Notes">{info.adjustor_notes}</Descriptions.Item>
                <Descriptions.Item span={6} label="Settled Amount">{Number(info.settled_amount).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item span={6} label="Settled Amount Classification">To include a breakdown of the settled amount. Classification to be based on the configured benefits of the product</Descriptions.Item>
            </Descriptions>

            <br/>

            <UpdateClaim />
        </React.Fragment>
    )
}