import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Space } from "antd";
import ProductOwners from "../../logic/productOwners";

const FormFields = [
  {
    label: "Client Name",
    name: "name",
    placeholder: "e.g. XX Insurance Company",
    rules: [
      {
        required: true,
        message: "Please enter the official name of the client",
      },
    ],
    size: 12,
  },
  {
    label: "Client Email",
    name: "email",
    placeholder: "e.g. insurance@xx.com",
    rules: [
      {
        required: true,
        message: "Please enter the official email of the client",
      },
    ],
    size: 12,
  },
  {
    label: "Telephone No.",
    name: "phone",
    placeholder: "e.g. 25671234567",
    rules: [
      {
        required: true,
        message: "Please enter the official phone number of the client",
      },
    ],
    size: 12,
  },
  {
    label: "Address",
    name: "address",
    placeholder: "e.g. Muganzilwaza Mall",
    rules: [
      {
        required: true,
        message: "Please enter the official address of the client",
      },
    ],
    size: 12,
  },
];

const ContactPersonFormFields = [
  {
    label: "First Name",
    name: "firstName",
    placeholder: "e.g. John",
    rules: [
      {
        required: true,
        message: "Please enter the first name of the contact person",
      },
    ],
    size: 8,
  },
  {
    label: "Last Name",
    name: "lastName",
    placeholder: "e.g. Doe",
    rules: [
      {
        required: true,
        message: "Please enter the last name of the contact person",
      },
    ],
    size: 8,
  },
  {
    label: "Email",
    name: "email",
    placeholder: "e.g. name@client.com",
    rules: [
      {
        required: true,
        message: "Please enter the email of the contact person",
      },
    ],
    size: 8,
  },
  {
    label: "Phone No.",
    name: "phone",
    placeholder: "e.g. 25671234567",
    rules: [
      {
        required: true,
        message: "Please enter the phone number of the contact person",
      },
    ],
    size: 8,
  },
  {
    label: "Position",
    name: "position",
    placeholder: "e.g. CEO",
    rules: [
      {
        required: true,
        message: "Please enter the position of the contact person",
      },
    ],
    size: 8,
  },
];

const coloredClassName =
  "text-2xl relative z-10 font-bold text-transparent bg-gradient-to-tl from-[#413E79] to-[#F8B34E] bg-clip-text";
const okButton =
  "bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]";

const AddProductOwner = (props) => {
  const [fetching, setFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const myData = props.location.state && props.location.state.itemToEdit;

  useEffect(() => {
    if (myData !== undefined) {
      // If in edit mode, populate the form with the details of the item being edited
      setIsEditing(props.location.state.isEditing);
    }
  }, [isEditing]);

  const onFinish = async (values) => {
    setFetching(true);
    // console.log('Received values of form:', values);
    //process the editing
    if (myData !== undefined){
      await new ProductOwners(props, values).edit();
      setFetching(false);
    }
    else{
      await new ProductOwners(props, values).add();
      setFetching(false);
    }
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        {myData !== undefined ? `Edit` : `Add`} Product Owner
      </h3>
      
      <h3
        style={{ fontSize: "1.0rem", marginBottom: "10px" }}
        className={coloredClassName}
      >
        Client Details
      </h3>

      <Card style={{ marginBottom: "10px" }}>
        <Row gutter={10} style={{ marginBottom: "10px" }}>
          {FormFields.map((field, index) => {
            if (myData !== undefined) {
              const itemToEdit = props.location.state.itemToEdit;
              return (
                <Col
                  lg={field.size}
                  sm={field.size}
                  xs={24}
                  key={index}
                  border="1"
                >
                  <Form.Item
                    key={index}
                    label={field.label}
                    name={field.name}
                    rules={field.rules}
                    initialValue={itemToEdit[field.name]}
                  >
                    <Input placeholder={field.placeholder} />
                  </Form.Item>
                </Col>
              );
            }
            return (
              <Col
                lg={field.size}
                sm={field.size}
                xs={24}
                key={index}
                border="1"
              >
                <Form.Item
                  key={index}
                  label={field.label}
                  name={field.name}
                  rules={field.rules}
                >
                  <Input placeholder={field.placeholder} />
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Card>

      {/* contact people form */}
      <h3
        style={{ fontSize: "1.0rem", marginBottom: "10px" }}
        className={coloredClassName}
      >
        Contact People
      </h3>

      {myData !== undefined ? (
        <Form.List
          initialValue={props.location.state.itemToEdit.contactPeople}
          name="contactPeople"
          rules={[
            {
              required: true,
              validator: async (_, contactPeople) => {
                if (!contactPeople || contactPeople.length < 1) {
                  return Promise.reject(
                    new Error("Please provide atleast one contact person")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Card style={{ marginBottom: "10px" }}>
                    <Row gutter={10}>
                      {ContactPersonFormFields.map((req, index) => {
                        return (
                          <Col
                            lg={req.size}
                            sm={req.size}
                            xs={24}
                            key={index}
                            border="1"
                          >
                            <Form.Item
                              fieldKey={field.index}
                              label={req.label}
                              name={[field.name, req.name]}
                              rules={req.rules}
                            >
                              <Input placeholder={req.placeholder} />
                            </Form.Item>
                          </Col>
                        );
                      })}
                      <Col sm={24} lg={8} md={8}>
                        <Button
                          size="small"
                          danger
                          style={{ marginTop: "30px" }}
                          onClick={() => remove(field.name)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    //   block
                  >
                    Add Contact Person
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </div>
            );
          }}
        </Form.List>
      ) : (
        <Form.List
          // initialValue={props.location.state.itemToEdit.contactPeople}
          name="contactPeople"
          rules={[
            {
              required: true,
              validator: async (_, contactPeople) => {
                if (!contactPeople || contactPeople.length < 1) {
                  return Promise.reject(
                    new Error("Please provide atleast one contact person")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Card style={{ marginBottom: "10px" }}>
                    <Row gutter={10}>
                      {ContactPersonFormFields.map((req, index) => {
                        return (
                          <Col
                            lg={req.size}
                            sm={req.size}
                            xs={24}
                            key={index}
                            border="1"
                          >
                            <Form.Item
                              fieldKey={field.index}
                              label={req.label}
                              name={[field.name, req.name]}
                              rules={req.rules}
                            >
                              <Input placeholder={req.placeholder} />
                            </Form.Item>
                          </Col>
                        );
                      })}
                      <Col sm={24} lg={8} md={8}>
                        <Button
                          size="small"
                          danger
                          style={{ marginTop: "30px" }}
                          onClick={() => remove(field.name)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    //   block
                  >
                    Add Contact Person
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </div>
            );
          }}
        </Form.List>
      )}

      <Form.Item>
        {myData !== undefined ? (
          <Space size="large">
            <Button
            className={okButton}
            htmlType="submit"
            loading={fetching}
          >
            <span style={{color: 'white'}}>Save Changes</span>
          </Button>
            <Button
              danger
              onClick={() => {
                props.history.push("/product-owners/");
              }}
            >
              Cancel
            </Button>
          </Space>
        ) : (
          <Button
            className={okButton}
            htmlType="submit"
            loading={fetching}
            block
          >
            <span style={{color: 'white'}}>Submit</span>
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default AddProductOwner;
