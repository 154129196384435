
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import store from '../store/store';
import { setProducts } from "../store/reducers/products";


class PolicyProfiles {
    constructor(props, data, ){
        this.props = props;
        this.data = data;
        this.notificationStatus = {
            0: "success",
            3: "error",
            undefined: "error"
        };
    }
    /**
     * get the list of policy profiles
     */
    async get(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: "api/v1/admin-portal/records/policy-profiles/",
            contentType: "application/json",
          })
        if (apiResponse.status === 200){
            return apiResponse.data
        } else {
            notification.error({
                description: apiResponse.message
            })
        }
    }
}

export default PolicyProfiles;