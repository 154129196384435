import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Modal, Form, InputNumber, Input, notification, Checkbox, Tag,
    Card, Radio, Row, Col, Upload
  } from "antd";
import CrudIntegration from '../../../logic/crudIntegration';
import { UploadOutlined } from "@ant-design/icons";


const AddSupportingDocument = (props) => {
    const { Title } = Typography;
    const [fetching, setFetching] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ confirmation, setConfirmation] = useState(false);

    const [ file, setFile ] = useState(null);
    

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm()

    useEffect(()=>{
      form.setFieldsValue(props.profile);
    })

    const uploadDocument = async data => {

        console.log(data)
        console.log(file)
        
        const formdata = new FormData();
        formdata.append('claim', props.claim)
        formdata.append('file_name', data.file_name)
        formdata.append('file', file)

        const status = await new CrudIntegration().create(
            'api/v1/admin-portal/records/claim-supporting-documents/',
            formdata,
            null
        )
        setFetching(false)
        if (status === 200){
            form.resetFields()
            handleCancel()
        }
    }

    return (
      <React.Fragment>
        <Button onClick={showModal} type="button">
        Add Document
      </Button>

      <Modal
        title={<center><Title level={5}>Add Supporting Document</Title></center>}
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
        >

        <Form
            form={form}
            encType="multipart/form-data"
            name="upload_supporting_document"
            layout = 'vertical'
            onFinish={uploadDocument}
            requiredMark
            clear
            >
                <Form.Item
                  name="file_name"
                  label="File Name"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of the file you are uploading",
                    },
                  ]}
                >
                  <Input placeholder='e.g. National ID'/>
                </Form.Item>

                <Form.Item
                    label="File"
                    name="file"
                    onChange={(e)=>{setFile(e.target.files[0])}}
                    rules={[
                    {
                        required: true,
                        message: 'Please select a document to upload',
                    },
                    ]}
                >
                    <Input type="file" />
                </Form.Item>

                <Checkbox 
                    style={{
                        marginTop: "15px", 
                        marginBottom: "15px"
                    }}
                    onChange={()=>{setConfirmation(!confirmation);}}
                    value={confirmation}
                >I confirm that the above details are valid</Checkbox>

                { confirmation ?
                    <Form.Item>
                        <Button 
                          htmlType="html"
                          disable={fetching}
                          loading={fetching}
                          style={{ width: "100%" }}
                          >Upload Document</Button>
                    </Form.Item>
                    : null
                }

            </Form>
      </Modal>
      </React.Fragment>
    );
  }
  
  export default AddSupportingDocument;
  