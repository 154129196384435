import React, {useEffect} from "react";

import { Provider } from 'react-redux'
import store from './store/store';


// import { Switch, Route, Redirect } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";


// authentication
import Login from "./pages/Login";

// overview
 import Overview from "./pages/Overview/Overview";

// clients
import ListProductOwners from "./pages/ProductOwners/List";
import AddProductOwner from "./pages/ProductOwners/AddProductOwner";
import Demo from './pages/ProductOwners/po';

import Products from "./logic/products";
import ProductOwners from "./logic/productOwners";

// policies
import ListPolicyProfiles from "./pages/PolicyProfiles/List";
// import PolicyDetails from "./pages/PolicyProfiles/PolicyDetails";
import PolicyDetail from './pages/PolicyProfiles/PolicyDetail';

// products
import ListProducts from "./pages/Products/List";
import ProductForm from "./pages/Products/ProductForm";

// service tickets
import ListServiceTickets from "./pages/ServiceTickets/List";

// payments
import ListPayments from "./pages/Payments/List";

import Dashboard from "./pages/Dashboard";
import ActivePolicyDetails from "./pages/ActivePolicyDetails";
import UserProfile from "./pages/UserProfile";

// claims
import ListClaims from './pages/Claims/List';
import ClaimDetail from "./pages/Claims/ClaimDetail";

// smes
import ListSmes from "./pages/Smes/List";

import Main from "./components/layout/Main";

import "antd/dist/antd.css";

import './assets/styles/index.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Profile from "./pages/Profile";
import ListCustomers from "./pages/Customers/List";
import AddCustomerForm from "./pages/AddCustomer/CustomerForm";

import ListTransactions from './pages/Transactions/List'



function App() {
  useEffect(()=>{
    const ignorePaths = ["/", "/login"]
    // if(!ignorePaths.includes(window.location.pathname)){
    //   new ProductOwners().get()
    // } else {
    //   console.info('not getting product owners')
    // }
    // new Products().get()
  })
  return (
    <Provider store={store}>
    <div className="App">
      <Switch>
        
        {/* authentication */}
        {<Route path="/" exact component={Login} /> }
        
        <Main>
          {/* overview */}
          <Route exact path="/overview/" component={Overview} />
          {/* dashboard for weerinde */}
          {/* <Route exact path="/overview/" component={Dashboard} /> */}

          {/* product owners */}
          <Route exact path="/product-owners/" component={ListProductOwners} />
          <Route exact path="/add-product-owner/" component={AddProductOwner} />

          {/* products */}
          <Route exact path="/products/" component={ListProducts} />
          <Route exact path="/add-product/" component={ProductForm} />

          {/* policies */}
          <Route exact path="/policy-profiles/" component={ListPolicyProfiles} />
          <Route exact path="/policy-detail/:id/" component={PolicyDetail} />
          {/* <Route exact path="/policy-details/" component={PolicyDetails} /> */}

          {/* service tickets */}
          <Route exact path="/support-tickets/" component={ListServiceTickets} />
          
          {/* payments */}
          <Route exact path="/payments" component={ListPayments} />
          <Route exact path="/transactions" component={ListTransactions} />
          
          {/* claims */}
          <Route exact path="/claims/" component={ListClaims} />
          <Route exact path="/claim-detail/:id/" component={ClaimDetail} />

          {/* smes */}
          <Route exact path="/smes/" component={ListSmes} />

          {/* customer profiles */}
          <Route exact path="/add-customer/" component={AddCustomerForm} />
          <Route exact path="/customer-profiles/" component={ListCustomers} />
          {/* customers i.e. end users */}
          <Route exact path="/customer-profile/:id/" component={UserProfile} />

          {/* <Route exact path="/demo/" component={Demo} />
          <Route exact path="/policy/:id/details/" component={ActivePolicyDetails} />
          <Route exact path="/profile/" component={Profile} /> */}
          {/* <Redirect from="*" to="/" /> */}
          {/* <Route exact path="/profile/" component={Profile} /> */}
        </Main>
      </Switch>
    </div>
    </Provider>
  );
}

export default App;
