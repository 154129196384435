import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Space,
} from "antd";
import Products from "../../logic/products";
import { coloredClassName, okButton } from "../../styleClassNames";

const ProductBenefitsForm = [
  {
    label: "Benefit",
    name: "benefit",
    placeholder: "e.g. Medical",
    rules: [
      { required: true, message: "Please enter the name of the benefit" },
    ],
    size: 8,
  },
  {
    label: "Category",
    name: "category",
    placeholder: "e.g. inpatient",
    rules: [{ required: true, message: "Please enter the category" }],
    size: 8,
  },
  {
    label: "Description",
    name: "description",
    placeholder: "e.g. Something write up about the benefit. (Optional)",
    rules: [
      {
        required: true,
        message: "Please enter the description of the benefit",
      },
    ],
    size: 8,
  },
  {
    label: "Max. Category Cover",
    name: "maxCategoryCover",
    placeholder: "e.g. 10,000,000",
    rules: [
      {
        required: true,
        message: "Please enter the maximum cover that comes with the benefit",
      },
    ],
    size: 8,
  },
];

const ProductForm = (props) => {
  const [fetching, setFetching] = useState(false);
  const onFinish = async (values) => {
    setFetching(true);
    if (myData !== undefined) {
      await new Products(props, values).edit();
    }
    await new Products(props, values).add();
    setFetching(false);
  };
  const myData = props.location.state && props.location.state.itemToEdit;

  
  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        {myData !== undefined ? `Edit` : `Add`} Product
      </h3>

      <Card style={{ marginBottom: "10px" }}>
        <Row gutter={10} style={{ marginBottom: "10px" }}>
          <Col lg={12} sm={12} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Product Name"
                name="productName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the product",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.productName}
              >
                <Input placeholder="e.g. Product X - Category A" />
              </Form.Item>
            ) : (
              <Form.Item
                label="Product Name"
                name="productName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the product",
                  },
                ]}
              >
                <Input placeholder="e.g. Product X - Category A" />
              </Form.Item>
            )}
          </Col>

          <Col lg={12} sm={12} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Product Owner"
                name="owner"
                rules={[
                  {
                    required: true,
                    message: "Please select the product owner",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.ownerDetails.name}
              >
                <Select style={{ height: "30px" }}>
                  <Select.Option value="1">Product Owner 1</Select.Option>
                  <Select.Option value="2">Product Owner 2</Select.Option>
                  <Select.Option value="3">Product Owner 3</Select.Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label="Product Owner"
                name="owner"
                rules={[
                  {
                    required: true,
                    message: "Please select the product owner",
                  },
                ]}
              >
                <Select style={{ height: "30px" }}>
                  <Select.Option value="1">Product Owner 1</Select.Option>
                  <Select.Option value="2">Product Owner 2</Select.Option>
                  <Select.Option value="3">Product Owner 3</Select.Option>
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col lg={12} sm={12} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Product Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type of the product",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.type}
              >
                <Select style={{ height: "30px" }}>
                  <Select.Option value="medical">Medical</Select.Option>
                  <Select.Option value="motor">Motor</Select.Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label="Product Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type of the product",
                  },
                ]}
              >
                <Select style={{ height: "30px" }}>
                  <Select.Option value="medical">Medical</Select.Option>
                  <Select.Option value="motor">Motor</Select.Option>
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col lg={12} sm={12} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Product Period"
                name="policyPeriod"
                rules={[
                  {
                    required: true,
                    message: "Please select the policy period for the product",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.policyPeriod}
              >
                <Select style={{ height: "30px" }}>
                  <Select.Option value="daily">Daily</Select.Option>
                  <Select.Option value="weekly">Weekly</Select.Option>
                  <Select.Option value="monthly">Monthly</Select.Option>
                  <Select.Option value="quarterly">Quarterly</Select.Option>
                  <Select.Option value="bi-annually">Bi-Annually</Select.Option>
                  <Select.Option value="annually">Annually</Select.Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label="Product Period"
                name="policyPeriod"
                rules={[
                  {
                    required: true,
                    message: "Please select the policy period for the product",
                  },
                ]}
              >
                <Select style={{ height: "30px" }}>
                  <Select.Option value="daily">Daily</Select.Option>
                  <Select.Option value="weekly">Weekly</Select.Option>
                  <Select.Option value="monthly">Monthly</Select.Option>
                  <Select.Option value="quarterly">Quarterly</Select.Option>
                  <Select.Option value="bi-annually">Bi-Annually</Select.Option>
                  <Select.Option value="annually">Annually</Select.Option>
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col lg={12} sm={12} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Cost"
                name="cost"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the cost of the product to the end customer",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.cost}
              >
                <InputNumber
                  placeholder="e.g. 30000"
                  style={{ width: "100%" }}
                  min={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Cost"
                name="cost"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the cost of the product to the end customer",
                  },
                ]}
              >
                <InputNumber
                  placeholder="e.g. 30000"
                  style={{ width: "100%" }}
                  min={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            )}
          </Col>

          <Col lg={12} sm={12} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Max Cover Cost by Insurer"
                name="maxCover"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the max cover that the insurer can provide for the product",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.maxCover}
              >
                <InputNumber
                  placeholder="e.g. 3,000,000"
                  style={{ width: "100%" }}
                  min={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Max Cover Cost by Insurer"
                name="maxCover"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the max cover that the insurer can provide for the product",
                  },
                ]}
              >
                <InputNumber
                  placeholder="e.g. 3,000,000"
                  style={{ width: "100%" }}
                  min={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            )}
          </Col>

          <Col lg={24} sm={24} xs={24}>
            {myData !== undefined ? (
              <Form.Item
                label="Product Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter a brief description of the product",
                  },
                ]}
                initialValue={props.location.state.itemToEdit.description}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="e.g. who the target customer is, what is covers"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Product Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter a brief description of the product",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="e.g. who the target customer is, what is covers"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Card>

      <h3
        style={{ fontSize: "1.0rem", marginBottom: "10px" }}
        className={coloredClassName}
      >
        Product Benefits
      </h3>

      {myData !== undefined ? (
        <Form.List
          initialValue={props.location.state.itemToEdit.benefits}
          name="benefits"
          rules={[
            {
              required: true,
              validator: async (_, benefits) => {
                if (!benefits || benefits.length < 1) {
                  return Promise.reject(
                    new Error(
                      "Please provide atleast one benefit of the product."
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Card style={{ marginBottom: "10px" }}>
                    <Row gutter={10}>
                      {ProductBenefitsForm.map((req, index) => {
                        return (
                          <Col
                            lg={req.size}
                            sm={req.size}
                            xs={24}
                            key={index}
                            border="1"
                          >
                            <Form.Item
                              fieldKey={field.index}
                              label={req.label}
                              name={[field.name, req.name]}
                              rules={req.rules}
                            >
                              <Input placeholder={req.placeholder} />
                            </Form.Item>
                          </Col>
                        );
                      })}
                      <Col sm={24} lg={8} md={8}>
                        <Button
                          size="small"
                          danger
                          style={{ marginTop: "30px" }}
                          onClick={() => remove(field.name)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    //   block
                  >
                    Add Benefit
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </div>
            );
          }}
        </Form.List>
      ) : (
        <Form.List
          name="benefits"
          rules={[
            {
              required: true,
              validator: async (_, benefits) => {
                if (!benefits || benefits.length < 1) {
                  return Promise.reject(
                    new Error(
                      "Please provide atleast one benefit of the product."
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Card style={{ marginBottom: "10px" }}>
                    <Row gutter={10}>
                      {ProductBenefitsForm.map((req, index) => {
                        return (
                          <Col
                            lg={req.size}
                            sm={req.size}
                            xs={24}
                            key={index}
                            border="1"
                          >
                            <Form.Item
                              fieldKey={field.index}
                              label={req.label}
                              name={[field.name, req.name]}
                              rules={req.rules}
                            >
                              <Input placeholder={req.placeholder} />
                            </Form.Item>
                          </Col>
                        );
                      })}
                      <Col sm={24} lg={8} md={8}>
                        <Button
                          size="small"
                          danger
                          style={{ marginTop: "30px" }}
                          onClick={() => remove(field.name)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    //   block
                  >
                    Add Benefit
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </div>
            );
          }}
        </Form.List>
      )}

      {myData !== undefined ? (
        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Space size="large">
            <Button className={okButton} htmlType="submit" loading={fetching}>
              <span style={{ color: "white" }}>Save Changes</span>
            </Button>
            <Button
              htmlType="cancel"
              danger
              onClick={() => {
                props.history.push("/products/");
              }}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      ) : (
        <Form.Item>
          <Button
            className={okButton}
            htmlType="submit"
            loading={fetching}
            block
          >
            <span style={{ color: "white" }}>Submit</span>
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default ProductForm;
