import React, { useState } from 'react';
import { Modal, Form, Input, InputNumber, Button, Tag, notification, Select} from 'antd';
import CrudIntegration from '../../../logic/crudIntegration';


const RecordSmePaymentModal = (props) => {
    const [visible, setVisible] = useState(false);
    const [fetching, setFetching] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const submitPayment = async data => {
        setFetching(true)
        let postData = {...data, sme: props.sme.id};
        setFetching(false);
        const records = await new CrudIntegration().create(
            'api/v1/admin-portal/records/record-sme-payment/',
            postData,
            'application/json'
        );
        try{
            if (records !== 400){ props.getSmes()}  
        } catch(err){
            notification.error({
                description: 'Sorry, an error occurred while recording the payment'
            })
        }
        setFetching(false);
    };

    return (
        <>
            <Tag color="green" onClick={showModal}>Record Payment</Tag>
            <Modal
                title={`Record Payment  for ${props.sme.name}`}
                open={visible}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form layout='vertical' onFinish={submitPayment}>
                    <Form.Item 
                        label="Amount Paid" 
                        name="amount"
                        rules={[{
                            required: true,
                            message: 'Please enter the amount paid' 
                        }]}>
                        <InputNumber
                            defaultValue={1000}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            style={{width: '100%'}}
                            />
                    </Form.Item>
                    <Form.Item 
                        label="Transaction Channel" 
                        name="transaction_channel"
                        rules={[{
                            required: true,
                            message: 'Please enter payment channel e.g. mobile money, bank' 
                        }]}>
                            <Select>
                                <Select.Option value="mobile_money">Mobile Money</Select.Option>
                                <Select.Option value="bank">Bank</Select.Option>
                                <Select.Option value="cash">Cash</Select.Option>
                            </Select>
                    </Form.Item>

                    <Form.Item 
                        label="Bank / Telecom" 
                        name="service_provider"
                        rules={[{
                            required: true,
                            message: 'Please enter the service provider e.g. cash, MTN, Centenary, Stanbic' 
                        }]}>
                            <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Transaction ID / Cheque No. / Receipt No." 
                        name="transaction_reference"
                        rules={[{
                            required: true,
                            message: 'Please enter the transaction reference' 
                        }]}>
                            <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Description / Notes" 
                        name="transaction_description"
                        rules={[{
                            required: true,
                            message: 'Please enter a description for the payment' 
                        }]}>
                            <Input.TextArea rows={2} />
                    </Form.Item>
                    
                    <Form.Item style={{marginTop: '20px'}}>
                        <Button 
                            className="bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]"
                            type="primary" 
                            htmlType="submit" 
                            style={{width: '100%'}}
                            loading={fetching}
                            >
                            Record Payment
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default RecordSmePaymentModal;