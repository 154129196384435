import { createSlice } from '@reduxjs/toolkit';

export const productOwnersSlice = createSlice({
  name: 'productOwners',
  initialState: {
    fetching: true,
    list: []
  },
  reducers: {
    setProductOwners: (state, action) => {
        state.fetching = false;
        state.list = action.payload;
    },
    }
});

// this is for dispatch
export const { setProductOwners } = productOwnersSlice.actions;

// this is for configureStore
export default productOwnersSlice.reducer;