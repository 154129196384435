import { configureStore } from '@reduxjs/toolkit'
import overviewReducer from './reducers/overview';
import authReducer from './reducers/auth';
import usersReducer from './reducers/users';

import productsReducer from './reducers/products';
import productOwnersReducer from './reducers/productOwners';  

import serviceTicketsReducer from './reducers/serviceTickets'

export default configureStore({
  reducer: {
    auth: authReducer,
    overview: overviewReducer,
    users: usersReducer,
    products: productsReducer,
    productOwners: productOwnersReducer,
    serviceTickets: serviceTicketsReducer
  }
});