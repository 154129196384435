import store from '../store/store';
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import { setUsers } from "../store/reducers/users";


class Customers {
    constructor(props, data, ){
        this.props = props;
        this.data = data;
        this.notificationStatus = {
            200: "success",
            400: "error",
            401: "error"
        };
    }

    /**
     * Get list of customers
     */
    async getCustomers(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            // body: {},
            endpoint: `/api/v1/customers/customer-list/`,
            contentType: "application/json",
          })
        if (apiResponse.status === 0){
            return apiResponse.records;
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }


    /**
     * Get the details of a customer
     */
    async getCustomerProfile(){
        const customerId = this.props.location.state.customer._id;
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: `/api/v1/customers/customer-profile/${customerId}`,
            contentType: "application/json",
          })
        if (apiResponse.status === 0){
            // store.dispatch(setUsers(apiResponse))
            return apiResponse.data
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }
}


export default Customers;