import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Col, Form, Input, Select, Button } from "antd";
import CrudIntegration from "../../logic/crudIntegration";
import { Link } from 'react-router-dom';


function ListClaims(props) {
  const [fetching, setFetching] = useState(true);
  const [claims, setClaims] = useState([]);
  const [showSearch] = useState(false);

  const getClaims = async () => {
    const records = await new CrudIntegration().get(
      'api/v1/admin-portal/records/claims/'
    );
    console.log(records)
    if (records !== 400){
      setClaims(records.records);
    }    
    setFetching(false);
  };

  useEffect(() => {
    getClaims();
  }, []);

  const ClaimStatusColors = {
    initiated: 'blue',
    submitted: 'cyan',
    processing: 'orange',
    settled: 'green',
    pending_settlement_approval: 'orange',
    settlement_approved: 'orange',
    rejected: 'red'
  }

  const columns = [
    {
      title: "Customer Phone",
      dataIndex: "customer",
      key: "customerContact",
      render: (text, record) => <Link to={`/claim-detail/${record.id}`}>{text.phone_number}</Link>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customer",
      render: (text) => (
        <span>{text.name}</span>
      ),
    },
    {
      title: "Policy",
      dataIndex: "product",
      key: "product",
      render: (text, record) => <span>{record.policy.name}</span>,
    },
    {
        title: "Policy Number",
        dataIndex: "policy_number",
        key: "policy_number",
        render: (text, record) => <span>{record.policy.policy_number}</span>,
    },
    { title: "Amount Claimed", dataIndex: "claimed_amount", key: "claimed_amount", render: text => <span>{Number(parseFloat(text)).toLocaleString()}</span> },
    { title: "Status", dataIndex: "claim_status", key: "status", render: text => <Tag color={ClaimStatusColors[text]}>{text}</Tag> },
    { title: "Amount Settled", dataIndex: "settled_amount", key: "settled_amount", render: text => <span>{Number(parseFloat(text)).toLocaleString()}</span> },
    { title: "Int. Date", dataIndex: "time_created", key: "time_created", render: text => <span>{new Date(text).toLocaleString()}</span> },
  ];

  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Claims
      </h3>

        {
        showSearch ?
        <Form layout='vertical'>
          <Row gutter={10}>
          
            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Policy Number"
                name="policy_number"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Phone Number"
                name="msisdn"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Intimation Date"
                name="intimation_date"
                style={{width: '100%'}}
              >
                <Input placeholder="e.g. 701234567" type="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Status"
                name="status"
                style={{width: '100%'}}
              >
                <Select>
                  <Select.Option>Initiated</Select.Option>
                  <Select.Option>Settled</Select.Option>
                  <Select.Option>Processing</Select.Option>
                  <Select.Option>Rejected</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={4} lg={4}>
              <Form.Item
                label="Action"
                style={{width: '100%'}}
              >
                <Button style={{borderColor: 'blue'}}>Filter</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        :
        null}
        
      {fetching ? (
        <Skeleton active />
      ) : (
        <Table
          style={{ marginTop: "20px" }}
          dataSource={claims}
          columns={columns}
          bordered
          size="small"
          pagination={{
            pageSize: 50
          }}
        />
      )}
    </React.Fragment>
  );
}

export default ListClaims;
