import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Modal, Form, InputNumber, Input, notification, Checkbox, Tag,
    Card, Radio, Row, Col
  } from "antd";
import CrudIntegration from '../../../logic/crudIntegration';


const AddClaimModal = (props) => {
    const { Title } = Typography;
    const [fetching, setFetching] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ confirmation, setConfirmation] = useState(false);
    

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm()

    useEffect(()=>{
      form.setFieldsValue(props.profile);
    })

    const submitClaim = async data => {
        setFetching(true)
        let postData = data;
        postData.policy = props.policyId
        let cleanData = {}

        // remove whatever is undefined
        Object.keys(postData).forEach(key => {
            if (postData[key] !== undefined) {
                cleanData[key] = postData[key];
            }
        });

        const status = await new CrudIntegration().create(
            'api/v1/admin-portal/records/claims/',
            cleanData,
            'application/json'
        )
        setFetching(false)
        if (status === 200){
            form.resetFields()
            handleCancel()
        }
    } 

    return (
      <React.Fragment>
        <Button onClick={showModal} type="button">
        Intimate Claim
      </Button>
      <Modal
        title={<center><Title level={5}>Intimate Claim</Title></center>}
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
        >
          <p>Policy Name: <strong>{props.policyName}</strong></p>
        <Form
            form={form}
            name="remit_revenue"
            layout = 'vertical'
            onFinish={submitClaim}
            requiredMark
            clear
            >
                <Form.Item
                  name="claimed_amount"
                  label="Claim Amount"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the amount claimed",
                    },
                  ]}
                >
                  <InputNumber 
                    placeholder="e.g. 1,000,000" 
                    style={{width: "100%"}}
                    min='10000'
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>

                <Form.Item
                  name="claim_submission_notes"
                  label="Claim Notes"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of the service provider",
                    },
                  ]}
                >
                  <Input.TextArea rows={3} placeholder="e.g. claimant says he was hospitalised" />
                </Form.Item>

                <Form.Item
                  name="sp_name"
                  label="Name of Service Provider"
                  >
                  <Input placeholder="e.g. Buddu Medical Centre" />
                </Form.Item>

                <Form.Item
                  name="sp_telephone"
                  label="Phone Number of Service Provider"
                  >
                  <Input placeholder="e.g. 0701234567" />
                </Form.Item>

                <Form.Item
                  name="sp_location"
                  label="Location of Service Provider"
                  >
                  <Input placeholder="e.g. Masaka" />
                </Form.Item>


                <Checkbox 
                    style={{
                        marginTop: "15px", 
                        marginBottom: "15px"
                    }}
                    onChange={()=>{setConfirmation(!confirmation);}}
                    value={confirmation}
                >I confirm that the above details are valid</Checkbox>

                { confirmation ?
                    <Form.Item>
                        <Button 
                          htmlType="html"
                          disable={fetching}
                          loading={fetching}
                          style={{ width: "100%" }}
                          >Intimate claim</Button>
                    </Form.Item>
                    : null
                }

            </Form>
      </Modal>
      </React.Fragment>
    );
  }
  
  export default AddClaimModal;
  