import React from 'react';
import Chart from 'react-apexcharts';

class SimpleLineGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'performance-by-count'
        },
        xaxis: {
          categories: ['Jan-23', 'Feb-23', 'Mar-23', 'Apr-23', 'May-23', 'Jun-23', 'Jul-23', 'Aug-23', 'Sep-23', 'Oct-23', 'Nov-23', 'Dec-23']
        }
      },
      series: [
        {
          name: 'Customer Registrations',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 321, 11]
        },
        {
            name: 'Policies',
            data: [40, 50, 45, 60, 59, 70, 80, 101, 225, 200, 221, 21]
        },
        {
            name: 'Count Claims Intimated',
            data: [0, 0, 5, 6, 0, 0, 0, 10, 25, 0, 1, 2]
        },
        {
            name: 'Count Claims Paid',
            data: [0, 0, 3, 1, 0, 0, 0, 0, 0, 0, 1, 2]
        },
        {
            name: 'Fraud Cases',
            data: [0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 1, 0]
        },
        {
          name: 'Premiums',
          data: [3000000, 4000000, 350000, 5000000, 490000, 600000, 7000000, 910000, 125000, 1000000, 321000, 11000]
        },
        {
            name: 'Commission',
            data: [40000, 500000, 45000, 60000, 59000, 70000, 80000, 101000, 225000, 200000, 221000, 21000]
        },
      ]
    };
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="line"
              width="1000"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleLineGraph;
