import React, {useState, useEffect} from "react";
import { coloredClassName, okButton } from '../../styleClassNames';
import {Alert, Row, Card, Space, Tag, Col, Statistic, Skeleton} from "antd";
import SimpleLineGraph from'./CountChart';
import CrudIntegration from "../../logic/crudIntegration";


const statLabels = [
    {label: 'No.Products', value: 'no_products', size: 4},
    {label: 'No. Customers', value: 'no_customers', size: 4},
    {label: "Today' Customers", value: 'no_today_customers', size: 4},
    {label: 'Incomplete Profiles', value: 'no_incomplete_profiles', size: 4},
    
    {label: 'Active Policies', value: 'no_active_policies', size: 4},
    {label: 'Inactive Policies', value: 'no_inactive_policies', size: 4},
    {label: 'Active Lives', value: 'no_active_lives', size: 4},
    {label: 'Inactive Lives', value: 'no_inactive_lives', size: 4},
    
    
    
    {label: 'Outstanding Claims', value: 'outstanding_claims', size: 4},
    {label: 'Claim Amount Paid', value: 'claims_paid', size: 5},
    {label: 'Annual Premiums', value: 'annual_premiums', size: 6},
    {label: 'Annual Commission', value: 'annual_commission', size: 6},
    {label: 'Fraud Alerts', value: 'no_fraud_alerts', size: 3},
    
]


const Overview = (props) => {
    const [ fetching, setFetching ] = useState(true)
    const [stats, setStats] = useState({});

    const getDashboardValues = async () => {
        const stats = await new CrudIntegration().get(
            'api/v1/admin-portal/dashboard/'
        );
        console.log(stats)
        if (stats !== 400){
            setStats(stats.insight);
        }    
        setFetching(false);
    };
    
    useEffect(() => { getDashboardValues()}, []);


    return(
        <React.Fragment>
            <h3 style={{fontSize:'1.875rem', marginBottom: '20px'}} className={coloredClassName}>Overview</h3>
            {
                fetching ? <Skeleton active />:
                <Row style={{marginBottom: '10px'}} gutter={5}>
                    {
                        statLabels.map((st, index) => {
                            return(
                                <Col xs={24} md={st.size} lg={st.size} style={{marginBottom: '10px'}}>
                                    <Card>
                                    <Statistic title={st.label} value={Number(stats[st.value]).toLocaleString()}/>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            }

            
            {/* <h4 style={{fontSize:'1.0rem', marginBottom: '20px'}} className={coloredClassName}>Performance over the past 12 months</h4> */}
            {/* {
                fetching ? <Skeleton active />:
                <Row style={{marginBottom: '10px'}} gutter={10}>
                    <SimpleLineGraph />
                </Row>
            } */}
            

        </React.Fragment>
    )
}

export default Overview;