import React, { useState, useEffect } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table } from "antd";
import Payments from "../../logic/payments";

const columns = [
  { title: "Contact", dataIndex: "msisdn", key: "msisdn" },
  { title: "Policy", dataIndex: "policy", key: "policy" },

  { title: "Amount", dataIndex: "amount", key: "amount" },

  { title: "Status", dataIndex: "status", key: "status" },
  {
    title: "Date Initiated",
    dataIndex: "timeInitiated",
    key: "timeInitiated",
    render: (text) => {
      const date = new Date(text.timeSeconds);
      console.log(date);
      return <span>{`${date}`}</span>;
    },
  },
];


const ListPayments = (props) => {
  const [payments, setPayments] = useState(null);
  const getPayments = async () => {
    await new Payments(props)
      .getPayments()
      .then((result) => setPayments(result.records));
  };
  
  useEffect(()=> {
    getPayments();
  }, [props])
  
  
  
  return (
    <React.Fragment>
      <h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Payments History
      </h3>

      <Table
        style={{ marginTop: "20px" }}
        dataSource={payments}
        columns={columns}
        bordered
        size="small"
      />
    </React.Fragment>
  );
}

export default ListPayments;
