import { callApi } from "../apiIntegration";
import { notification } from "antd";
import store from "../store/store";
import { setProducts } from "../store/reducers/products";

class Products {
  constructor(props, data) {
    this.props = props;
    this.data = data;
    this.notificationStatus = {
      0: "success",
      3: "error",
      undefined: "error",
    };
  }

  /**
   * add a product
   */
  async add() {
    const apiResponse = await callApi({
      requireAuth: true,
      method: "POST",
      body: JSON.stringify(this.data),
      endpoint: "/api/v1/client-mgt/products/",
      contentType: "application/json",
    });
    if (apiResponse.status === 0) {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
      this.get();
      this.props.history.push("/products/");
    } else {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
    }
  }

  /**
   * edit a product
   */
  async edit() {
    const apiResponse = await callApi({
      requireAuth: true,
      method: "POST",
      body: JSON.stringify(this.data),
      endpoint: "/api/v1/client-mgt/products/",
      contentType: "application/json",
    });
    if (apiResponse.status === 0) {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
      this.get();
      this.props.history.push("/products/");
    } else {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
    }
  }

  /**
   * get the list of products
   */
  async get() {
    const apiResponse = await callApi({
      requireAuth: false,
      method: "GET",
      body: JSON.stringify(this.data),
      endpoint: "api/v1/customer-portal/products/",
      contentType: "application/json",
    });
    if (apiResponse.status === 0) {
      // notification[this.notificationStatus[apiResponse.status]]({
      //     description: apiResponse.message
      // })
      // set this to the store
      // console.log(apiResponse.products)
      store.dispatch(setProducts(apiResponse.products));
    } else {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
    }
  }

  /**
   * disabling of a product
   */
  async delete() {
    const apiResponse = await callApi({
      requireAuth: true,
      method: "DELETE",
      body: JSON.stringify(this.data),
      endpoint: "/api/v1/client-mgt/products/",
      contentType: "application/json",
    });
    if (apiResponse.status === 0) {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
      this.get();
    } else {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
    }
  }

  async getAdminProducts(){
    const apiResponse = await callApi({
        requireAuth: true,
        method: "GET",
        body: {},
        endpoint: "api/v1/admin-portal/records/products/",
        contentType: "application/json",
      })
    if (apiResponse.status === 200){
        return apiResponse.data
    } else {
        notification.error({
            description: apiResponse.message
        })
    }
}
}

export default Products;
