import store from "../store/store";
import { callApi } from "../apiIntegration";
import { notification } from "antd";
import { setServiceTickets } from "../store/reducers/serviceTickets";

class ServiceTickets {
  constructor(props, data) {
    this.props = props;
    this.data = data;
    this.notificationStatus = {
      200: "success",
      400: "error",
      401: "error",
    };
  }

  /**
   * Get the details of a customer
   */
  async getServiceTickets() {
    const apiResponse = await callApi({
      requireAuth: true,
      method: "GET",
      // body: {},
      endpoint: `/api/v1/tickets/?status=open&policy=policyid&msisdn=256777777`,
      contentType: "application/json",
    });
    if (apiResponse.status === 0) {
      // store.dispatch(setServiceTickets(apiResponse.records));
      //console.log(apiResponse.records)
      return apiResponse
    } else {
      notification[this.notificationStatus[apiResponse.status]]({
        description: apiResponse.message,
      });
    }
  }
}

export default ServiceTickets;
