import React, { useEffect, useState } from "react";
import { coloredClassName } from "../../styleClassNames";
import { Table, Tag, Skeleton, Row, Col, Form, Input, Select, Button, notification } from "antd";
import PolicyProfiles from "../../logic/policyProfiles";
import Products from "../../logic/products";
import CrudIntegration from "../../logic/crudIntegration";


function AddCustomerForm(props) {
  const [fetching, setFetching] = useState(true);
  const [products, setProducts] = useState([]);
  
  
  const getProducts = async () => {
    const result = await new Products().getAdminProducts();
    setProducts(result.records);
    setFetching(false);
  };

  const createCustomer = async data => {
    setFetching(true);
    const records = await new CrudIntegration().create(
        'api/v1/admin-portal/records/new-customer/',
        data,
        'application/json'
    );
    try{
        if (records !== 400){
            notification.success({
                description: 'To confirm customer, check for customer under Insurance Policies.'
            })
        }  
    } catch(err){
        notification.error({
            description: 'Sorry, an error occurred while onboarding the customer'
        })
    }
      
    setFetching(false);
  };


  useEffect(() => {
    getProducts();
  }, []);

  return (
    <React.Fragment>
      <center><h3
        style={{ fontSize: "1.875rem", marginBottom: "20px" }}
        className={coloredClassName}
      >
        Onboard Customer
      </h3></center>

      <Row gutter={10}>
        <Col xs={24} md={4} lg={4} />
        <Col xs={24} md={16} lg={16}>
        <Form layout='vertical' onFinish={createCustomer}>
            <Form.Item
                label="Product"
                name="product"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please select a product' }]}
              >
                <Select>
                    {
                        products.map((product, index) => {
                            return <Select.Option key={index} value={product.id}>{product.name}</Select.Option>
                        })
                    }
                </Select>
              </Form.Item>

              <Form.Item
                label="Customer First Name"
                name="first_name"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please enter first name of the customer' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Customer Last Name"
                name="last_name"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please enter last name of the customer' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Date of Birth"
                name="date_of_birth"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the date of birth' }]}
              >
                <Input type='date'/>
              </Form.Item>

              <Form.Item
                label="Gender"
                name="gender"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please select the gender' }]}
              >
                <Select>
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="NIN"
                name="nin"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the NIN of the customer' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="msisdn"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the phone number of the customer' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Residential Address"
                name="residential_address"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the address of the customer' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Next of Kin' First Name"
                name="nok_first_name"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the first name of the next of kin' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Next of Kin' Last Name"
                name="nok_last_name"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the last name of the next of kin' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Next of Kin' Relationship"
                name="nok_relationship"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the relationship with the next of kin' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="Next of Kin' Phone Number"
                name="nok_msisdn"
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please provide the phone number of the next of kin' }]}
              >
                <Input/>
              </Form.Item>

              <Form.Item>
                <Button 
                    htmlType='submit' 
                    className="bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]"
                    style={{borderColor: 'blue', width: '100%'}}
                    disabled={fetching}
                    loading={fetching}
                    >Submit</Button>
              </Form.Item>
        </Form>

        </Col>        
      </Row>

    </React.Fragment>
  );
}

export default AddCustomerForm;
