import { createSlice } from '@reduxjs/toolkit';

export const serviceTicketsSlice = createSlice({
  name: 'serviceTickets',
  initialState: {
    fetching: true,
    list: []
  },
  reducers: {
    setServiceTickets: (state, action) => {
        state.fetching = false;
        state.list = action.payload;
    },
    }
});

// this is for dispatch
export const { setServiceTickets } = serviceTicketsSlice.actions;

// this is for configureStore
export default serviceTicketsSlice.reducer;