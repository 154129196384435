import React from "react";
import {Tabs, Row, Col, Typography, Button} from "antd";
import { SnippetsOutlined, SyncOutlined, FileSyncOutlined, InfoCircleOutlined, SoundOutlined } from "@ant-design/icons";

const {TabPane} = Tabs;
const {Title} = Typography;

const ActivePolicyDetails  = (props) => {
    return(
        <React.Fragment>
            <h3 style={{color: "red"}}>Policy Name | PolicyNumberWithUnderwriter</h3>
            <Button  style={{marginBottom: "10px"}}>Pay Subscription</Button>
            <Tabs
                defaultActiveKey="1"
                tabPosition='top'
                type='card'
                // centered
                tabBarGutter={10}
            >
                <TabPane
                    tab={<span><SnippetsOutlined />Policy Info / Status</span>}
                    key="1">
                    <Row style={{padding: '5px'}}>
                        details of the current policy as held by the underwriter<br/>
                        dependents will be listed here as well
                    </Row>
                </TabPane>

                <TabPane
                    tab={<span><FileSyncOutlined />Redemptions & Claims</span>}
                    key="2">
                    <Row style={{padding: '5px'}}>
                        the redemptions that the customer has made against the policy
                    </Row>
                </TabPane>

                <TabPane
                    tab={<span><SyncOutlined />Payment History</span>}
                    key="3">
                    <Row style={{padding: '5px'}}>
                        the payments that the customer has made for the policy
                    </Row>
                </TabPane>

                <TabPane
                    tab={<span><SoundOutlined/>Support tickets</span>}
                    key="4">
                    <Row style={{padding: '5px'}}>
                        the support tickets that the customer has raised regarding the policy
                    </Row>
                </TabPane>

                <TabPane
                tab={<span><InfoCircleOutlined />Policy Details</span>}
                key="5">
                    <Row style={{padding: '5px'}}>
                        details of the policy as offered by the underwriter<br/>
                        e.g. what the policy covers, policy duration
                    </Row>
                </TabPane>

                

            </Tabs>
        </React.Fragment>
        
    )
}

export default ActivePolicyDetails;