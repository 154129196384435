
// import { useState } from "react";
import { Badge, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { 
  DashboardOutlined,
  TeamOutlined,
  LogoutOutlined,
  NotificationOutlined,
  PropertySafetyOutlined,
  AlertOutlined,
  DownloadOutlined,
  UsergroupAddOutlined,
  AuditOutlined, BookOutlined, CopyOutlined, BankOutlined,
  SyncOutlined, UserAddOutlined
} from "@ant-design/icons";
import React from "react";
import logo from "../../assets/images/weerinde_logo.jpg"

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <React.Fragment >
      <div className="brand">
        <img src={logo} alt="" />
        <span>Weerinde Insurance<br/>Brokerage Services</span>
      </div>
      <hr />
      <Menu theme="dark" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/overview/">
            <span
              className="icon"
              style={{
                background: page === "overview/" ? color : "",
              }}
            >
              <DashboardOutlined />
            </span>
            <span className="label">Overview</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="support-tickets">
          <NavLink to="/support-tickets/">
            <span
              className="icon"
              style={{
                background: page === "support-tickets/" ? color : "",
              }}
            >
              <NotificationOutlined />
            </span>
            <span className="label">Support Tickets</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="add-customer">
          <NavLink to="/add-customer/">
            <span
              className="icon"
              style={{
                background: page === "add-customer/" ? color : "",
              }}
            >
              <UserAddOutlined />
            </span>
            <span className="label">Onboard Customer</span>
            {/* <Badge count={1}/> */}
          </NavLink>
        </Menu.Item>

        <Menu.Item key="claims">
          <NavLink to="/claims/">
            <span
              className="icon"
              style={{
                background: page === "claims/" ? color : "",
              }}
            >
              <NotificationOutlined />
            </span>
            <span className="label">Claims</span>
            {/* <Badge count={1}/> */}
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="Customer Profiles">
          <NavLink to="/customer-profiles/">
            <span
              className="icon"
              style={{
                background: page === "customer-profiles/" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Customer Profiles</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="payments">
          <NavLink to="/payments/">
            <span
              className="icon"
              style={{
                background: page === "payments/" ? color : "",
              }}
            >
              <DownloadOutlined />
            </span>
            <span className="label">Payments</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="transactions">
          <NavLink to="/transactions/">
            <span
              className="icon"
              style={{
                background: page === "transactions/" ? color : "",
              }}
            >
              <SyncOutlined />
            </span>
            <span className="label">Transactions</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="smes">
          <NavLink to="/smes/">
            <span
              className="icon"
              style={{
                background: page === "smes/" ? color : "",
              }}
            >
              <BankOutlined />
            </span>
            <span className="label">SMEs</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="policy-profiles">
          <NavLink to="/policy-profiles/">
            <span
              className="icon"
              style={{
                background: page === "policy-profiles/" ? color : "",
              }}
            >
              <CopyOutlined />
            </span>
            <span className="label">Insurance Policies</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="product-owners">
          <NavLink to="/product-owners/">
            <span
              className="icon"
              style={{
                background: page === "product-owners/" ? color : "",
              }}
            >
              <PropertySafetyOutlined />
            </span>
            <span className="label">Product Owners</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="products">
          <NavLink to="/products/">
            <span
              className="icon"
              style={{
                background: page === "products/" ? color : "",
              }}
            >
              <BookOutlined />
            </span>
            <span className="label">Products</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="alerts">
          <NavLink to="/alerts/">
            <span
              className="icon"
              style={{
                background: page === "alerts/" ? color : "",
              }}
            >
              <AlertOutlined />
            </span>
            <span className="label">Alerts<Badge count={0}/></span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="admin-users">
          <NavLink to="/admin-users/">
            <span
              className="icon"
              style={{
                background: page === "admin-users/" ? color : "",
              }}
            >
              <UsergroupAddOutlined />
            </span>
            <span className="label">Admin Users</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="actions-logs">
          <NavLink to="/action-logs/">
            <span
              className="icon"
              style={{
                background: page === "action-logs/" ? color : "",
              }}
            >
              <AuditOutlined />
            </span>
            <span className="label">Action Logs</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="logout">
          <NavLink to="/">
            <span className="icon"> <LogoutOutlined /></span>
            <span className="label">Logout</span>
          </NavLink>
        </Menu.Item>

      </Menu>
      
    </React.Fragment>
  );
}

export default Sidenav;
