import React from 'react';
import {Descriptions, Modal, Button} from 'antd';
import UpdatePersonalInfoModal from '../PolicyProfiles/components/UpdatePersonalInfoModal';

const PersonalInfoDetails = [
    {},
    {label: 'First Name', value: 'first_name', editable: true, type: 'text'},
    {label: 'Last Name', value: 'last_name', editable: true, type: 'text'},
    {label: 'Other Name(s)', value: 'other_names', editable: true, type: 'text'},
    {label: 'Gender', value: 'gender', editable: true, type: 'select', options: ['Male', 'Female']},
    {label: 'Date of birth', value: 'date_of_birth', editable: true, type: 'date'},
    {label: 'Marital Status', value: 'marital_status', editable: true, type: 'select', options: ['Single', 'Married', 'Divorced', 'Widowed', 'Engaged']},
    {label: 'Height (cm)', value: 'height_in_cm', editable: true, type: 'text'},
    {label: 'Weight (kgs)', value: 'weight_in_kg', editable: true, type: 'text'},
    {label: 'Blood Group', value: 'blood_group', editable: true, type: 'select', options: ['A', 'B', 'AB', 'O', 'N/A']},
    {label: 'Residential Address', value: 'residential_address', editable: true, type: 'text'},
    {label: 'Email', value: 'email', editable: true, type: 'text'},
    {label: 'NIN', value: 'nin', editable: true, type: 'text'},
]

const NokDetails = [
    {},
    {label: 'First Name', value: 'nok_first_name', editable: true, type: 'text'},
    {label: 'Last Name', value: 'nok_last_name', editable: true, type: 'text'},
    {label: 'Other Name(s)', value: 'nok_other_names', editable: true, type: 'text'},
    {label: 'Occupation', value: 'nok_occupation', editable: true, type: 'text'},
    {label: 'Phone Number', value: 'nok_phone_number', editable: true, type: 'text'},
    {label: 'Residential Address', value: 'nok_residential_address', editable: true, type: 'text'},
    {label: 'Other Phone Number', value: 'nok_secondary_phone_number', editable: true, type: 'text'},
    {label: 'Relationship', value: 'nok_relationship', editable: true, type: 'text'}
]

function PersonalInfo(props){
    const info = props.record;

    return (
    <React.Fragment>
    

    <Descriptions title="Personal Info" bordered style={{marginBottom: '10px'}}>
        <Descriptions.Item span={6} label="Registration Channel">{info.profile_source}</Descriptions.Item>
        <Descriptions.Item span={6} label="Phone Number">{info.phone_number}</Descriptions.Item>
        {
            PersonalInfoDetails.map((detail, index) => {
                return <Descriptions.Item key={index} span={6} label={detail.label}>{info[detail.value]}</Descriptions.Item>
            })
        }
        <Descriptions.Item span={12}>
            <UpdatePersonalInfoModal 
            formItems={PersonalInfoDetails}
            profile={info} 
            title="Personal Info"
            initialValues={PersonalInfoDetails.reduce((acc, detail) => {
                return {
                    ...acc,
                    [detail.value]: info[detail.value]
                }})
            }
            customerId={info.id}
        /></Descriptions.Item>
    </Descriptions>
    <br/>

    <Descriptions title="Next of kin" bordered>
        {
            NokDetails.map((detail, index) => {
                return <Descriptions.Item key={index} span={6} label={detail.label}>{info[detail.value]}</Descriptions.Item>
            })
        }
        <Descriptions.Item span={12}>
            <UpdatePersonalInfoModal 
            formItems={NokDetails} 
            profile={info} 
            title="Next of Kin Details"
            initialValues={NokDetails.reduce((acc, detail) => {
                return {
                    ...acc,
                    [detail.value]: info[detail.value]
                }})
            }
            customerId={info.id}
            /></Descriptions.Item>
    </Descriptions>


    </React.Fragment>)
}



export default PersonalInfo;