
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import store from '../store/store';
import { setProductOwners } from '../store/reducers/productOwners';

class ProductOwners {
    constructor(props, data, ){
        this.props = props;
        this.data = data;
        this.notificationStatus = {
            0: "success",
            3: "error",
            undefined: "error"
        };
    }

    /**
     * add a product owner
     */
    async add(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "POST",
            body: JSON.stringify(this.data),
            endpoint: "/api/v1/client-mgt/clients/",
            contentType: "application/json",
          })
        if (apiResponse.status === 0){
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
            this.props.history.push('/product-owners/')
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }

    /** 
     * get the list of product owners 
    */
    async get(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: JSON.stringify(this.data),
            endpoint: "/api/v1/client-mgt/clients/",
            contentType: "application/json",
          })
        if (apiResponse.status === 0){
            // notification[this.notificationStatus[apiResponse.status]]({
            //     description: apiResponse.message
            // })
            // set this to the store
            // console.log(apiResponse.products)
            store.dispatch(setProductOwners(apiResponse.data))
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }

    /**
     * edit a product owner   
     */
    async edit(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "PUT",
            body: JSON.stringify(this.data),
            endpoint: "/api/v1/client-mgt/clients/",
            contentType: "application/json",
          })
        if (apiResponse.status === 0){
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
            this.props.history.push('/product-owners/')
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }


    /**
     * disable a product owner   
     */
    async delete(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "DELETE",
            body: JSON.stringify(this.data),
            endpoint: "/api/v1/client-mgt/clients/",
            contentType: "application/json",
          })
        if (apiResponse.status === 0){
            notification[this.notificationStatus[apiResponse.status]]({
              description: apiResponse.message,
            });
            this.get();
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }

}

export default ProductOwners;