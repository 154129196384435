// const apiDomain = "http://localhost:8000/";
// const apiDomain = 'https://weerinde-api-dev.ksgit.cloud/'
const apiDomain = 'https://weerinde-api-prod.ksgit.cloud/'

const callApi = async (data) => {
    try{
        const URL = `${apiDomain}${data.endpoint}`;
    
    let headers = { "Content-type": data.contentType }
    if (data.contentType === undefined){
        delete headers['Content-type']
    }
    
    if (data.requireAuth){ 
        const token = await sessionStorage.getItem('token');
        const TOKEN  = `Bearer ${token}`;
        headers['Authorization'] = TOKEN;
    }

    let requestConsiderations = {
        method: data.method,
        body: data.body,
        headers: headers,
    }

    // to remove the body
    if (data.method === 'GET'){
        requestConsiderations = {
            method: data.method,
            headers: headers,
        }
    }

    if(data.contentType === 'ignore'){delete headers['Content-Type']}

    const ApiResponse = fetch( URL, requestConsiderations )
    .then( async response => {
        if (response.status === 401 && data.endpoint !== '/api/v1/auth/login/'){
            window.location.href="/";
        }
        return await response.json(); 
    })
    .catch(e => {console.log(e)})
    return ApiResponse;
    } catch(error){
        console.log(error);
        return false;
    }

}


export { callApi }