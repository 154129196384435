import React from 'react';
import {Descriptions} from 'antd';

function PolicyDetails(props){
    const info = props.policy

    return (
        <React.Fragment>
        <Descriptions title="Details according to Weerinde" bordered style={{marginBottom: '10px'}}>
            <Descriptions.Item span={6} label="Time Created">{new Date(info.time_created).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="SME">{info.sme}</Descriptions.Item>
            <Descriptions.Item span={6} label="Product Name">{info.product.name}</Descriptions.Item>
            <Descriptions.Item span={6} label="Time Joined">{new Date(info.time_created).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Policy Status">{info.policy_status}</Descriptions.Item>
            <Descriptions.Item span={6} label="Is Dependant">{info.is_dependant ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item span={6} label="Weerinde' Policy Start Date">{info.calculated_start_date === null ? '' : new Date(info.calculated_start_date).toLocaleDateString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Weerinde' Policy End Date">{info.calculated_end_date === null ? '' : new Date(info.calculated_end_date).toLocaleDateString()}</Descriptions.Item>
            {/* <Descriptions.Item span={6} label="Weerinde' Policy Start Date">{info.calculated_start_date}</Descriptions.Item>
            <Descriptions.Item span={6} label="Weerinde' Policy End Date">{info.calculated_end_date}</Descriptions.Item> */}
            
            <Descriptions.Item span={6} label="Time Last Updated">{new Date(info.time_last_updated).toLocaleString()}</Descriptions.Item>
            
        </Descriptions>

        <Descriptions title="Financials" bordered>
            <Descriptions.Item span={6} label="No. Missed Payments">{info.count_missed_payments}</Descriptions.Item>
            <Descriptions.Item span={6} label="Amount. Missed Payments">UGX {info.amount_missed_payments}</Descriptions.Item>
            <Descriptions.Item span={6} label="Next Payment Amount">UGX {info.next_payment_amount === null ? '' : Number(parseFloat(info.next_payment_amount)).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Next Payment Date">{info.next_payment_date}</Descriptions.Item>
            

            <Descriptions.Item span={6} label="Ann. Payments">UGX {Number(info.annual_cumulative_payments).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Ann. Claims Paid">UGX {Number(info.annual_cumulative_claims).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Gross Cum. Payments">UGX {Number(info.gross_cumulative_payments).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Gross Cum. Claims Paid">UGX {Number(info.gross_cumulative_claims).toLocaleString()}</Descriptions.Item>
        </Descriptions>

        <Descriptions title="Details according to Underwriter" bordered>
            <Descriptions.Item span={6} label="Member Number">{info.underwriter_member_number}</Descriptions.Item>
            <Descriptions.Item span={6} label="Policy Number">{info.underwriter_policy_number}</Descriptions.Item>
            <Descriptions.Item span={6} label="Member Status">{info.underwriter_member_status}</Descriptions.Item>
            <Descriptions.Item span={6} label="Policy Start Date">{info.underwriter_policy_start_date === null ? '' : Date(info.underwriter_policy_start_date).toLocaleDateString()}</Descriptions.Item>
            <Descriptions.Item span={6} label="Policy End Date">{info.underwriter_policy_end_date === null ? '' : Date(info.underwriter_policy_end_date).toLocaleDateString()}</Descriptions.Item>
        </Descriptions>
        </React.Fragment>
    )
}

export default PolicyDetails;