import React, { useState, useEffect } from "react";
import { Tabs, Row, Button, Col, Divider, List, Card, Descriptions, Empty } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  TeamOutlined,
  MedicineBoxOutlined,
  BankOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Customers from "../logic/customer";

const { TabPane } = Tabs;

const UserProfile = (props) => {
  // define customer profile record state
  const [profile, setProfile] = useState(null);

  // get customer profile
  const getCustomerProfile = async () => {
    await new Customers(props)
      .getCustomerProfile()
      .then((result) => setProfile(result));
  };

  useEffect(() => {
    getCustomerProfile();
  }, [props]);

  return (
    <React.Fragment>
      <span>
        <h4>Customer Details</h4>
        {profile != undefined ? (
          <h3>{`${profile.firstName} ${profile.surname}`}</h3>
        ) : null}
      </span>
      <Tabs
        defaultActiveKey="1"
        tabPosition="top"
        type="card"
        // centered
        tabBarGutter={10}
        style={{ padding: "15px" }}
      >
        <TabPane
          tab={
            <span>
              <UserOutlined />
              Personal and Contact Information
            </span>
          }
          key="personalInfo"
        >
          <Row style={{ padding: "15px" }}>
            <Col>
              {/* the personal information that a user submitted during registration
              i.e.
              <Divider orientation="left">Personal Info</Divider> */}
              {/* <ul>
                <li>personal information</li>
                <li>contact information</li>
                <li>next of kin</li>
              </ul> */}
              <div>
                {profile != undefined ? (
                  <>
                    <Descriptions title="Personal Info" bordered>
                      <Descriptions.Item label="First Name">{`${profile.firstName}`}</Descriptions.Item>
                      <Descriptions.Item label="Last Name">
                        {profile.surname ? `${profile.surname}` : `N/A`}
                      </Descriptions.Item>
                      <Descriptions.Item label="D.O.B">
                        {profile.dob ? `${profile.dob}` : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Gender">
                        {profile.gender ? `${profile.gender}` : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Height">
                        {profile.height ? `${profile.height}` : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Weight">
                        {profile.weight ? `${profile.weight}` : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Blood Group">
                        {profile.bloodGroup ? `${profile.bloodGroup}` : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Marital Status">
                        {profile.maritalStatus
                          ? `${profile.maritalStatus}`
                          : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Country of Residence">
                        {profile.countryOfResidence
                          ? `${profile.countryOfResidence}`
                          : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Occupation">
                        {profile.occupation ? `${profile.occupation}` : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address">
                        {profile.residentialAddress
                          ? `${profile.residentialAddress}`
                          : "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions title="Contact Info" bordered>
                      <Descriptions.Item label="Telephone">
                        {profile.primaryPhone
                          ? `${profile.primaryPhone}`
                          : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Office Phone">
                        {profile.officePhoneNumber
                          ? `${profile.officePhoneNumber}`
                          : "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">
                        {profile.primaryPhone ? `${profile.email}` : "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </TabPane>

        <TabPane
          tab={
            <span>
              <MedicineBoxOutlined />
              Medical Declaration
            </span>
          }
          key="medicalDeclaration"
        >
          <Row style={{ padding: "15px" }}>
            {/* the medical declaration of the user */}
            {/* <Col> */}
            {profile && profile.medicalDeclaration ? (
              <>
                <Divider orientation="left">Activity</Divider>
                <List
                  size="small"
                  // header={<div>Header</div>}
                  // footer={<div>Footer</div>}
                  bordered
                  dataSource={profile.medicalDeclaration.activity}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
                <Divider orientation="left">Declarations</Divider>
                <List
                  size="small"
                  // header={<div>Header</div>}
                  // footer={<div>Footer</div>}
                  bordered
                  dataSource={profile.medicalDeclaration.declarations}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
                <Divider orientation="left"></Divider>
                <Card title="Notes">{profile.medicalDeclaration.notes ? `${profile.medicalDeclaration.notes}` : <><Empty /></>}</Card>
              </>
            ) : (
              <>
                <Card title="No Medical Declarations Found"></Card>
              </>
            )}
            {/* </Col> */}
          </Row>
        </TabPane>

        <TabPane
          tab={
            <span>
              <TeamOutlined />
              Dependants
            </span>
          }
          key="dependants"
        >
          <Row style={{ padding: "5px" }}>the dependants</Row>
        </TabPane>

        <TabPane
          tab={
            <span>
              <BankOutlined />
              Assets & Declarations
            </span>
          }
          key="assetsAndDeclaration"
        >
          <Row style={{ padding: "5px" }}>
            the registered assets of the user i.e. vehicles, homes <br />
            these are the assets insured through Weerinde as a broker
          </Row>
        </TabPane>

        <TabPane
          tab={
            <span>
              <BankOutlined />
              Insurance Policies
            </span>
          }
          key="insurancePolicies"
        >
          <Row style={{ padding: "5px" }}>
            the insurance policies of the customer
          </Row>
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default UserProfile;
