import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Modal, Form, InputNumber, Input, notification, Checkbox, Tag,
    Card, Radio, Row, Col, Select
  } from "antd";
import CrudIntegration from '../../../logic/crudIntegration';
import { set } from 'firebase/database';


const UpdatePersonalInfoModal = (props) => {
    const { Title } = Typography;
    const [fetching, setFetching] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ confirmation, setConfirmation] = useState(false);
    const [form] = Form.useForm();
    

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(()=>{
      form.setFieldsValue(props.initialValues)
      console.log(props.initialValues)
  }, [form, props.initialValues])

    const submitProfileUpdate = async data => {
      setFetching(true);
      for (let key in data){
        if (data[key] === undefined || data[key] === null || data[key] === ''){
          delete data[key];
        }
      }
      data.id = props.customerId;
      const response = await new CrudIntegration().update(
        `api/v1/admin-portal/records/customer-profiles/`,
        data,
        'application/json'
      )
      if (response === 200){ setIsModalOpen(false); }
      setFetching(false);
    } 

    return (
      <React.Fragment>
        <p style={{color: 'blue'}} onClick={showModal}>Update {props.title}</p>
      <Modal
        title={<center><Title level={5}>Update {props.title} for {props.profile.phone_number}</Title></center>}
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
        >
        <Form
            form={form}
            name="update_personal_profile"
            layout = 'vertical'
            onFinish={submitProfileUpdate}
            requiredMark
            clear
            >
              {
                props.formItems.map((item, index) => {
                  return <Form.Item
                   key={index}
                   name={item.value}
                   label={item.label}
                  >
                    {
                      item.type === 'text' ?
                      <Input placeholder="" />
                      :
                      item.type === 'date' ?
                      <Input placeholder="" type='date' />
                      :
                      item.type === 'select' ?
                      <Select>
                        {
                          item.options.map((option, index) => {
                            return <Select.Option key={index} value={option}>{option}</Select.Option>
                          })
                        }
                      </Select>
                      :
                      null
                    }

                  </Form.Item>
                })
              }
               

                <Checkbox 
                    style={{
                        marginTop: "15px", 
                        marginBottom: "15px"
                    }}
                    onChange={()=>{setConfirmation(!confirmation);}}
                    value={confirmation}
                >I confirm that the above details are valid</Checkbox>

                { confirmation ?
                    <Form.Item>
                        <Button 
                          htmlType="html"
                          disable={fetching}
                          loading={fetching}
                          style={{ width: "100%" }}
                          >Submit changes</Button>
                    </Form.Item>
                    : null
                }

            </Form>
      </Modal>
      </React.Fragment>
    );
  }
  
  export default UpdatePersonalInfoModal;
  