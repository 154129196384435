import store from '../store/store';
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import { setAuth } from "../store/reducers/auth";
import ProductOwners from './productOwners';

class Auth {
    constructor(props, data, ){
        this.props = props;
        this.data = data;
        this.notificationStatus = {
            200: "success",
            400: "error",
            401: "error"
        };
    }
    /**
     * login the user
     */
    async login(){
        const apiResponse = await callApi({
            requireAuth: false,
            method: "POST",
            body: JSON.stringify(this.data),
            endpoint: "api/v1/customer-portal/authentication/login/",
            contentType: "application/json",
          })

        if (apiResponse.status === 200){
            // await sessionStorage.setItem('token', apiResponse.data);
            await sessionStorage.setItem('token', apiResponse.data.access_token);
            // await sessionStorage.setItem('username', apiResponse.profile.email);
            // store.dispatch(setAuth(apiResponse.profile))
            // await new ProductOwners().get();
            this.props.history.push('/overview/')
        } else {
            notification.error({
                description: apiResponse.message,
            });
            return false;
        }
    }
    

    /**
     * Gets all the accounts to consider
     */
    // getAll(){
    //     callApi({
    //         requireAuth: true,
    //         method: "GET",
    //         body: JSON.stringify({}),
    //         endpoint: "api/v1/accounts/list",
    //         contentType: "application/json",
    //     })
    //     .then(async (res) => {
    //         await store.dispatch(setAccounts(res.data));
    //     })
    //     .catch((err)=>{
    //         notification.error({
    //             description: "An error occurred while retrieving your accounts",
    //         })
    //     })  
    // }

    /**
     * Submits changes to the details of an account
     */
    // edit(){
    //     callApi({
    //         requireAuth: true,
    //         method: "PUT",
    //         body: JSON.stringify(this.data),
    //         endpoint: "api/v1/accounts/",
    //         contentType: "application/json",
    //     })
    //     .then(async (res) => {
    //         notification[this.notificationStatus[res.status]]({
    //             description: res.message
    //         })
    //         this.getAll();
    //     })
    //     .catch((err)=>{
    //         console.log(err);
    //         notification.error({
    //             description: "An error occurred while updating the account details",
    //         })
    //     })  
    // }
}


export default Auth;